import React from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '../../..';
import { HeaderHighlight } from '../../..';
import { MoreButton } from '../../../MoreButton';
import style from './style.module.scss';
import { ToggleAppointmentModal } from '../../../../Redux/Actions';

const FaqBoxHeader = () => {
    return (
        <Flex column align='flex-start' style={{ width: '100%' }}>
            <HeaderHighlight background="#fff" />
            <h3>Top Questions</h3>
        </Flex>
    )
}

export const FaqBox = () => {
    const dispatch = useDispatch()

    const handleClick = (e: any) => {
        dispatch(ToggleAppointmentModal(true));
    }

    return (
        <Flex center column className={style.faqBox}>
            <FaqBoxHeader />
            <p>
                Still have questions? No problem, request an appointment with one of our advisors and we’re happy to help!
            </p>
            <MoreButton
                onClick={handleClick}
                iconClass={style.buttonIcon}
                buttonClass={style.buttonClass}
            >
                Request Appointment
            </MoreButton>
        </Flex>
    );
};
