
type Environment = 'staging' | 'local' | 'production'


export const NonProdEnvironments = new Set<Environment>(['staging', 'local'])

const RegexPatterns: [RegExp, Environment][] = [
  [/staging/, 'staging'],
  [/localhost/, 'local']
]



export const ResolveEnvironment = (): Environment => {
  const href = window.location.href;
  if (!RegexPatterns.some(p => p[0].test(href))) {
    console.warn('Unknown environment! Defaulting to production');
    return 'production';
  } else { return (RegexPatterns.find(p => p[0].test(href)) || [/./, 'production'])[1]; }
}