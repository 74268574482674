import React, { useMemo, useEffect } from "react";
import { Flex, HeaderHighlight } from "../..";
import { Button } from "../../Button";
import style from "./style.module.scss";
import {
  GetHomeLandingAction,
  GetHomeSectionTwoAction,
} from "../../../Redux/Actions/ContentActions";
import LandingVideo from "./LandingVideo";
import { LandingMobileBg } from "./LandingMobileBg";
import { AppState } from "../../../Redux/Models";
import { useSelector, useDispatch } from "react-redux";
import { ToggleAppointmentModal } from "../../../Redux/Actions";
import { WindowSizes } from "../../../Redux/Models/Utility/WIndowSizes";
import ReactMarkdown from "react-markdown";
import { FormatQuoteRounded, FormatQuoteTwoTone } from "@mui/icons-material";

const windowSizeSelector = (s: AppState) => s.utility.appWindowSize;
const dataSelector = (s: AppState) => s.content.homeSectionTwo.data;

export const LandingSection = React.memo((props: any) => {
  const dispatch = useDispatch();
  const appSize = useSelector(windowSizeSelector);
  const data = useSelector(dataSelector);

  const QuoteSignature = () => {
    return (
      <Flex align="center" justify="flex-start" style={{ width: "100%" }}>
        <div className={style.signatureHighlight} />
        <span className={style.signature}>Glen Turner</span>
      </Flex>
    );
  };

  useEffect(() => {
    dispatch(GetHomeSectionTwoAction());
  }, [dispatch]);

  const buttonStyles = useMemo(() => {
    switch (appSize) {
      case WindowSizes.mobile:
      case WindowSizes.smallMobile:
      case WindowSizes.tinyMobile:
        return { fontSize: 15, padding: "10px 20px" };
      default:
        return { fontSize: 20, padding: "10px 30px" };
    }
  }, [appSize]);

  const isVideo = useMemo(() => {
    switch (appSize) {
      case WindowSizes.regular:
        return <LandingVideo />;
      // case WindowSizes.tablet:
      case WindowSizes.smallTablet:
        return <LandingMobileBg />;
      case WindowSizes.mobile:
        return <LandingMobileBg />;
      case WindowSizes.smallMobile:
        return <LandingMobileBg />;
      case WindowSizes.tinyMobile:
        return <LandingMobileBg />;
      default:
        return <LandingVideo />;
    }
  }, [appSize]);

  useEffect(() => {
    dispatch(GetHomeLandingAction());
  }, [dispatch]);

  return (
    <Flex center className={style.wrapper}>
      <div className={style.video_section}>{isVideo}</div>
      <div className={style.video_overlay}> </div>
      {data && (
        <Flex column className={style.hero_grabber_section}>
          <h1>{"Protect Your Future"}</h1>
          <h2 className={style.headline}>
            <FormatQuoteRounded
              className={`${style.quote} ${style.leftQuote}`}
            />
            Proper planning and effective action is the foundation of securing
            one's wealth.
            <FormatQuoteRounded className={`${style.quote}`} />
          </h2>
          {/* <QuoteSignature /> */}
          {/* <ReactMarkdown>{`${data?.SectionContent}`}</ReactMarkdown> */}
          <Flex>
            <Button
              theme="callToActionWhite"
              className={style.landing_call_to_action}
              onClick={() => dispatch(ToggleAppointmentModal(true))}
              containerStyle={{
                padding: buttonStyles.padding,
                zIndex: 1,
                borderWidth: "3px",
              }}
              labelStyle={{ fontSize: buttonStyles.fontSize }}
            >
              Get Started
            </Button>
          </Flex>
        </Flex>
      )}
      <div className={style.home_hero_scroll_text}>SCROLL</div>
    </Flex>
  );
});
