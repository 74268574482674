import react from 'react';
import { Flex } from '../../../Flex';
import { BulletItem } from '../../../BulletItem/BulletItem';
import style from './style.module.scss';

const ContentSection = (props: any) => (
    <>
        <p style={{ width: '80%', lineHeight: 1.5, fontSize: '1.25rem' }}>{props.paragraph}</p>
    </>
);

interface ContentWithBulletPointsProps {
    points: any[];
    content?: any[];
    color?: string;
}

export const ContentWithBulletPoints = (props: ContentWithBulletPointsProps) => {
    const { points, content, color } = props;
    return (
        <Flex
            className={style.content_wrapper}
            center
            style={{ width: '100%', color: color ? color : '#fff' }}
        >
            <Flex column center style={{ flex: 1 }}>
                {content?.map((c, idx) => <ContentSection id={`bullet-section-${idx}`} paragraph={c.paragraph} />)}
            </Flex>
            <Flex className={style.bullet_points} column style={{ flex: 0.7 }}>
                {points?.map(p => <BulletItem content={p.point} />)}
            </Flex>
        </Flex>
    )
};