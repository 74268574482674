import React, { useMemo } from 'react'
import { Hero } from '../..';
import { Flex } from '../..';
import { Events, CurveBg, CurveBottomBg } from '../../../Assets';
import style from "./style.module.scss";
import { AppState } from '../../../Redux/Models';
import { WindowSizes } from "../../../Redux/Models/Utility/WIndowSizes";
import { useSelector } from 'react-redux';
import { ReusableStaticImage } from '../..';
import { HeaderHighlight } from '../..';

const Header = (props: { content: any }) => {
    return (
        <Flex column className={style.hero_content_events}>
            <HeaderHighlight background="#FE4900" />
            {props.content}
        </Flex>
    )
}

export const LandingSection = (props: { grabber?: any, headerContent: any, image?: any, hideShadow?: boolean }) => {
    const appSize = useSelector((s: AppState) => s.utility.appWindowSize)


    const buttonStyles = useMemo(() => {
        switch (appSize) {
            case WindowSizes.large: return "callToActionWhite"
            case WindowSizes.regular: return "callToActionWhite"
            case WindowSizes.small: return "callToActionBlack"
            case WindowSizes.tablet: return "callToActionBlack"
            case WindowSizes.smallTablet: return "callToActionBlack"
            case WindowSizes.mobile: return "callToActionBlack"
            case WindowSizes.smallMobile: return "callToActionBlack"
            case WindowSizes.tinyMobile: return "callToActionBlack"
            default: return "callToActionWhite"
        }
    }, [appSize])

    return (
        <Flex column className={style.hero_wrapper}>
            <ReusableStaticImage image={CurveBg} width={400} />
            <Flex className={style.waveFill}>
                <div className={style.box}></div>
                <Hero
                    color="#fff"
                    hideShadow={props.hideShadow}
                    buttonTheme={buttonStyles}
                    header={<Header content={props.headerContent} />}
                    grabber={props.grabber}
                    height="90%"
                    image={Events}
                />
            </Flex>
            <ReusableStaticImage image={CurveBottomBg} width={400} />
        </Flex>
    );
};
