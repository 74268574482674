import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  PlaylistAddCheck,
  DraftsTwoTone,
  NoteTwoTone,
  LibraryBooksTwoTone,
  VideoLibraryTwoTone,
  VideoLabelTwoTone,
  MicTwoTone,
  ForumTwoTone,
  FeaturedPlayListTwoTone,
} from "@mui/icons-material";
import { Flex } from "../../../Components";
import { GetArticlesAction } from "../../../Redux/Actions";
import { AppState } from "../../../Redux/Models";
import { ContentThumbnail } from "../ContentThumbnail";
import style from "./style.module.scss";
import { CardItem } from "../MediaCard";
import { MediaCard } from "../MediaCard";
import { SectionHeader } from "../SectionHeader";

export const ResourcesSection = () => {
  const dispatch = useDispatch();
  const data = useSelector((s: AppState) => s?.content?.articles?.data);
  const cardItems: CardItem[] = [
    {
      title: "Videos",
      id: "videos",
      onLearnMore: () => console.log(`Clicked Videos`),
      IconComponent: VideoLibraryTwoTone,
    },
    {
      title: "E-Books",
      id: "e-books",
      onLearnMore: () => console.log("Clicked e-books"),
      IconComponent: LibraryBooksTwoTone,
    },
    {
      title: "White Papers",
      id: "white-papers",
      onLearnMore: () => console.log("Clicked white papers"),
      IconComponent: NoteTwoTone,
    },
    {
      title: "Infographics",
      id: "infographics",
      onLearnMore: () => console.log("Clicked Infographic"),
      IconComponent: VideoLabelTwoTone,
    },
    {
      title: "Podcasts",
      id: "podcasts",
      onLearnMore: () => console.log("Clicked Podcasts"),
      IconComponent: MicTwoTone,
    },
    {
      title: "News",
      id: "news",
      onLearnMore: () => console.log("Clicked News"),
      IconComponent: FeaturedPlayListTwoTone,
    },
    // {
    //     title: "Newsletters",
    //     id: "newsletters",
    //     onLearnMore: () => console.log("Clicked Newsletters"),
    //     IconComponent: DraftsTwoTone,
    // },
    // {
    //     title: "Blog",
    //     id: "blog",
    //     onLearnMore: () => console.log("Clicked Blog"),
    //     IconComponent: ForumTwoTone,
    // },
  ];

  useEffect(() => {
    dispatch(GetArticlesAction());
  }, [dispatch]);

  return (
    <Flex column className={style.wrapper}>
      <SectionHeader
        content={
          <h2 id="education">
            Education
            <br />
            Resources
          </h2>
        }
      />
      <Flex wrap center className={style.cardWrapper}>
        {/* {cardItems.map((c) => <MediaCard IconComponent={c.IconComponent} title={c.title} id={c.id} />)} */}
        {/* <ContentThumbnail /> */}
        {data && data?.Article?.map((d: any) => <ContentThumbnail data={d} />)}
      </Flex>
    </Flex>
  );
};
