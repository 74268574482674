import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Flex } from "../..";
import { LandingMobileBg } from "./LandingMobileBg";
import style from "./style.module.scss";

const LandingVideo = React.memo(() => {
  const [loaded, setLoaded] = useState(false);
  const videoUrl = "https://www.youtube.com/watch?v=R7T-sSXP3TM&ab";

  return (
    <Flex center className={style.video_flex_wrapper}>
      <Flex center style={{ height: "100%", zIndex: 1 }}>
        <LandingMobileBg />
        {/* <ReusableStaticImage width={8} image={PrimaryLogo} style={style.logo} /> */}
      </Flex>
      <div className={style.video_overlay}> </div>
      <ReactPlayer
        style={{ visibility: loaded ? "visible" : "hidden" }}
        className={style.video}
        width="100%"
        onStart={() => setLoaded(true)}
        height="100%"
        loop={true}
        muted={true}
        playsinline={true}
        controls={false}
        stopOnUnmount={false}
        url={videoUrl}
        playing={true}
        config={{
          youtube: {
            playerVars: {
              showinfo: 0,
            },
          },
        }}
      />
    </Flex>
  );
});

export default LandingVideo;
