
const pluralize = (value: string, plural: boolean = true) => plural ? `${value}s` : value

export const Collections = {
  businessServiceSection: (plural: boolean = true) => pluralize('business-service-section', plural),
  individualServiceSection: (plural: boolean = true) => pluralize('individual-service-section', plural),

  // Single Types -- added by Glen Turner.
  homeLandingSection: (plural: boolean = false) => pluralize(`home-landing-section`, plural),
  homeSectionOne: (plural: boolean = false) => pluralize(`home-section-one`, plural),
  homeSectionTwo: (plural: boolean = false) => pluralize(`home-section-two`, plural),
  aboutLandingSection: (plural: boolean = false) => pluralize(`about-landing-section`, plural),
  aboutSectionOne: (plural: boolean = false) => pluralize(`about-section-one`, plural),
  aboutTeam: (plural: boolean = false) => pluralize(`about-team`, plural),
  article: (plural: boolean = false) => pluralize('article-section', plural),
  metaTag: (plural: boolean = false) => pluralize('main-meta-tag', plural),
}
