import React, { useMemo } from "react";
import { InlineWidget } from "react-calendly";
import { AppState } from "../../Redux/Models";
import { WindowSizes } from "../../Redux/Models/Utility/WIndowSizes";
import { useSelector } from 'react-redux';
import { Flex } from "..";

export const CalendlyInlineWidget = () => {
    const appSize = useSelector((s: AppState) => s.utility.appWindowSize)

    const styles = useMemo(() => {
        switch (appSize) {
            case WindowSizes.large: return { width: '90vw', height: '80vh', padding: 0, margin: 0 }
            case WindowSizes.regular: return { width: '90vw', height: '80vh', padding: 0, margin: 0 }
            case WindowSizes.tablet: return { width: '90vw', height: '80vh', padding: 0, margin: 0 }
            case WindowSizes.smallTablet: return { width: '90vw', height: '90vh', padding: 0, margin: 0 }
            case WindowSizes.mobile: return { width: '90vw', height: '90vh', padding: 0, margin: 0 }
            case WindowSizes.smallMobile: return { width: '90vw', height: '90vh', padding: 0, margin: 0 }
            case WindowSizes.tinyMobile: return { width: '100vw', height: '92vh', padding: 0, margin: 0 }
            default: return { width: '90vw', height: '80vh', padding: 0, margin: 0 }
        }
    }, [appSize])

    return (
        <>
            <InlineWidget url="https://calendly.com/andrew-wilkins1?hide_gpdr_banner=1" styles={styles} />

            {/* <iframe style={{ border: 'transparent', background: '#fff' }} id="calcFrame" title="md" src="http://www.library-messages.com/61177fdf-da52-4208-9d94-8eec2a504d15/resource-center/tax/tax-deductions-you-wont-believe" width="800" height="1000"></iframe> */}
        </>
    );
};

// http://www.library-messages.com/61177fdf-da52-4208-9d94-8eec2a504d15//resource-center/retirement/estimate-your-rmd
