import { IIndividualServiceSection } from ".";
import { ApiData } from "../ApiData";
import { IBusinessServiceSection } from "./IBusinessServiceSection";
import { Articles } from "./Articles";

export class ContentState {
  businessServices: ApiData<IBusinessServiceSection[]>
  individualServices: ApiData<IIndividualServiceSection[]>
  metaDataTags: ApiData<any>
  articles: ApiData<any>
  homeLandingSection: ApiData<any>
  aboutLandingSection: ApiData<any>
  aboutSectionOne: ApiData<any>
  aboutTeam: ApiData<any>
  homeSectionOne: ApiData<any>
  homeSectionTwo: ApiData<any>

  constructor() {
    this.businessServices = new ApiData([]);
    this.individualServices = new ApiData([]);
    this.articles = new ApiData([]);
    this.metaDataTags = new ApiData([])
    this.aboutLandingSection = new ApiData();
    this.aboutSectionOne = new ApiData();
    this.aboutTeam = new ApiData();
    this.homeLandingSection = new ApiData();
    this.homeSectionOne = new ApiData();
    this.homeSectionTwo = new ApiData();
  }
}