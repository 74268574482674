import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Card, CardItem } from "..";
import { PortraitCard, PortraitCardProps } from "../../PortraitCard";
import { Flex } from "../..";
import style from "./style.module.scss";
import { gsap } from "gsap";
import { useSelector } from "react-redux";
import { AppState } from "../../../Redux/Models";
import { useEffect } from "react";
import { WindowSizes } from "../../../Redux/Models/Utility/WIndowSizes";

export interface CardCarouselProps {
  // cardItems: CardItem[];
  cardItems: PortraitCardProps[];
  id?: any;
}

export const CardCarousel = React.memo((props: CardCarouselProps) => {
  let leftCard = useRef(null);
  // let centerCard = useRef(null);
  let rightCard = useRef(null);
  let centerList = useRef(null);
  const appSize = useSelector((s: AppState) => s.utility.appWindowSize);
  const [animating, setAnimating] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(3);
  const [incomingCalc, setIncomingCalc] = useState<any>(null);

  useEffect(() => {
    switch (appSize) {
      case WindowSizes.mobile:
      case WindowSizes.smallMobile:
      case WindowSizes.tinyMobile:
        setStartIndex(0);
        setEndIndex(1);
        break;
      case WindowSizes.tablet:
      case WindowSizes.smallTablet:
        setStartIndex(0);
        setEndIndex(2);
        break;
      default:
        setStartIndex(0);
        setEndIndex(3);
        break;
    }
  }, [appSize]);

  const arrowFontSize = useMemo(() => {
    switch (appSize) {
      case WindowSizes.smallMobile:
      case WindowSizes.tinyMobile:
        return 30;
      default:
        return 60;
    }
  }, [appSize]);

  const cardWrapperHeight = useMemo(() => {
    switch (appSize) {
      case WindowSizes.regular:
      case WindowSizes.large:
        return "410px";
      default:
        return "310px";
    }
  }, [appSize]);

  const getIncomingCalc = useCallback(() => {
    const centerCardWrapper = document.getElementById("center-card-wrapper");
    if (!centerCardWrapper) return null;
    switch (appSize) {
      case WindowSizes.smallMobile:
      case WindowSizes.tinyMobile:
        return {
          xOrigin: centerCardWrapper?.clientWidth * 2,
          xEndPosition: 0,
          width: "80%", //centerCardWrapper.clientWidth,
          centerEndPosition: centerCardWrapper.clientWidth,
        };
      case WindowSizes.mobile:
        return {
          xOrigin: centerCardWrapper?.clientWidth * 2,
          xEndPosition: 0,
          width: "100%", //centerCardWrapper.clientWidth,
          centerEndPosition: centerCardWrapper.clientWidth,
        };
      case WindowSizes.tablet:
      case WindowSizes.smallTablet:
        return {
          xOrigin: centerCardWrapper?.clientWidth * 1.5,
          xEndPosition: centerCardWrapper.clientWidth / 2,
          width: "50%", // centerCardWrapper.clientWidth,
          centerEndPosition: centerCardWrapper.clientWidth,
        };
      default:
        return {
          xOrigin: centerCardWrapper?.clientWidth * 2,
          xEndPosition: centerCardWrapper.clientWidth,
          width: "33%", // centerCardWrapper.clientWidth,
          centerEndPosition: centerCardWrapper.clientWidth,
        };
    }
  }, [appSize]);

  const animateLeft = useCallback(() => {
    setAnimating(true);
    const incomingCalc = getIncomingCalc();

    if (centerList?.current && rightCard?.current) {
      gsap.set(rightCard.current, {
        // @ts-ignore
        x: incomingCalc?.xOrigin,
        display: "flex",
        // @ts-ignore
        width: incomingCalc?.width,
      });
      gsap.to(
        rightCard.current,
        // @ts-ignore
        { x: incomingCalc?.xEndPosition, duration: 0.4 }
      );
      gsap.to(centerList.current, {
        // @ts-ignore
        x: -incomingCalc?.centerEndPosition,
        duration: 0.4,
        onComplete: () => {
          // reset...
          gsap.set(rightCard.current, {
            // @ts-ignore
            x: 0,
            display: "none",
            // @ts-ignore
          });
          gsap.set(centerList.current, {
            // @ts-ignore
            x: 0,
          });
          setStartIndex(startIndex + 1);
          setEndIndex(endIndex + 1);
          setAnimating(false);
        },
      });
    }
  }, [centerList, rightCard, startIndex, endIndex, getIncomingCalc]);

  const animateRight = useCallback(() => {
    setAnimating(true);
    const incomingCalc = getIncomingCalc();

    if (centerList?.current && leftCard?.current) {
      gsap.set(leftCard.current, {
        // @ts-ignore
        x: -incomingCalc?.xOrigin,
        display: "flex",
        // @ts-ignore
        width: incomingCalc?.width,
      });
      gsap.to(
        leftCard.current,
        // @ts-ignore
        { x: -incomingCalc?.xEndPosition, duration: 0.4 }
      );
      gsap.to(centerList.current, {
        // @ts-ignore
        x: incomingCalc?.centerEndPosition,
        duration: 0.4,
        onComplete: () => {
          // reset...
          gsap.set(leftCard.current, {
            // @ts-ignore
            x: 0,
            display: "none",
            // @ts-ignore
          });
          gsap.set(centerList.current, {
            // @ts-ignore
            x: 0,
          });
          setStartIndex(startIndex - 1);
          setEndIndex(endIndex - 1);
          setAnimating(false);
        },
      });
    }
  }, [endIndex, getIncomingCalc, startIndex]);

  // Create the visible list
  const visibleList = useMemo(
    () => props.cardItems.slice(startIndex, endIndex),
    [startIndex, endIndex, props.cardItems]
  );

  useEffect(() => {
    const calc = getIncomingCalc();
    setIncomingCalc(calc);
  }, [appSize, getIncomingCalc]);

  const handleLeftClick = useCallback(
    () => (startIndex > 0 && !animating ? animateRight() : null),
    [animating, startIndex, animateRight]
  );

  const handleRightClick = useCallback(
    () =>
      props.cardItems.length > endIndex && !animating ? animateLeft() : null,
    [props.cardItems, endIndex, animateLeft, animating]
  );

  // if(!incomingCalc) return null

  return (
    <Flex column center className={style.wrapper}>
      {/* <Flex
        center
        className={`${style.arrowSection} ${style.leftArrowSection} ${startIndex <= 0 ? style.disabledArrow : null
          }`}
        row
        onClick={handleLeftClick}
      >
        <ArrowBackIosRounded
          style={{
            cursor: "pointer",
            fontSize: arrowFontSize,
            color: "rgb(36,149,172)",
          }}
        />
      </Flex> */}
      <Flex center className={style.cardCarouselWrapper}>
        {startIndex > 0 && (
          <div ref={leftCard} className={style.outerCard}>
            {/* <Card cardItem={props.cardItems[startIndex - 1]} /> */}
            <PortraitCard {...props.cardItems[startIndex - 1]} />
          </div>
        )}
        <span
          ref={centerList}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Flex center className={style.centerList}>
            {visibleList.map((c, idx) => (
              <div
                key={idx}
                id="center-card-wrapper"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: incomingCalc?.width,
                }}
              >
                {/* <Card id={c.id} cardItem={c} /> */}
                <PortraitCard {...c} />
              </div>
            ))}
          </Flex>
        </span>
        {endIndex < props.cardItems.length && (
          <div
            ref={rightCard}
            className={style.outerCard}
            style={{ width: incomingCalc?.width }}
          >
            {/* <Card cardItem={props.cardItems[endIndex]} /> */}
            <PortraitCard {...props.cardItems[endIndex]} />
          </div>
        )}
      </Flex>
      {/* <Flex
        center
        row
        onClick={handleRightClick}
        className={`${style.arrowSection} ${style.rightArrowSection} ${endIndex >= props.cardItems.length ? style.disabledArrow : null
          }`}
      >
        <ArrowForwardIosRounded
          style={{
            cursor: "pointer",
            fontSize: arrowFontSize,
            color: "rgb(36,149,172)",
          }}
        />
      </Flex> */}
      <Flex center className={style.buttonWrapper}>
        <Flex
          center
          className={`${style.arrow} ${
            startIndex === 0 && style.arrowDisabled
          }`}
          onClick={handleLeftClick}
        >
          <ArrowBackIosRounded
            style={{
              cursor: startIndex === 0 ? "default" : "pointer",
              fontSize: 24,
              color: "rgb(36,149,172)",
              opacity: startIndex === 0 ? 0.5 : 1,
            }}
          />
        </Flex>
        <Flex
          center
          className={`${style.arrow} ${
            endIndex >= props.cardItems.length && style.arrowDisabled
          }`}
          onClick={handleRightClick}
        >
          <ArrowForwardIosRounded
            style={{
              cursor:
                endIndex >= props.cardItems.length ? "default" : "pointer",
              fontSize: 24,
              color: "rgb(36,149,172)",
              opacity: endIndex >= props.cardItems.length ? 0.5 : 1,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
});
