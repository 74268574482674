import React from 'react';
import { Flex, HeaderHighlight } from '../..';
import style from './style.module.scss';

const Particle = () => {
    return (
        <Flex align="center" style={{ position: 'absolute', top: '0', left: '0', bottom: '0', height: '100%' }}>
            <div className={style.particle}></div>
        </Flex>
    )
}

const Circle = () => {
    return (
        <Flex align="center" style={{ position: 'absolute', top: '0', left: '0', bottom: '0', height: '100%' }}>
            <div className={style.circle}></div>
        </Flex>
    )
}

export interface SectionHeaderProps {
    content: any;
    type?: "particle" | "circle" | "line";
    textStyle?: any;
}

export const SectionHeader = (props: SectionHeaderProps) => {
    if (props.type === 'line') return (
        <Flex column align="flex-start" className={style.header}>
            <HeaderHighlight classes={style.headerHighlight} />
            <h2 style={{ margin: 0, ...(props.textStyle || {}) }}>{props.content}</h2>
        </Flex>
    )
    return (
        <Flex justify="flex-start" align="center" className={style.header}>
            {props.type === "circle" ? <Circle /> : <Particle />}
            <h2 style={{ margin: 0, ...(props.textStyle || {}) }}>{props.content}</h2>
        </Flex>
    );
};
