import React, { useEffect } from 'react';
import style from './style.module.scss';
import { PieChartModel } from "./PieChartModel";
import Highcharts from 'highcharts/highcharts';
import Exporting from "highcharts/modules/map";
import { FormatNumber } from '../../../Utils';
Exporting(Highcharts);

interface PieChartProps {
  data: PieChartModel;
  targetId: string;
  showValue?: boolean;
  loading?: any;
  isNegative?: boolean;
}

const PieChart = React.memo((props: PieChartProps) => {
  const { data, showValue, loading, isNegative } = props;

  useEffect(() => {
    // TODO Radialize the colors, currently broken right now https://github.com/Sapien-Technologies/Aphrodite/issues/43 -- Glen Turner

    Highcharts.setOptions({
      // colors: Highcharts.map(Highcharts.getOptions().colors || [],
      //     (color: any) => {
      //         return {
      //             radialGradient: {
      //                 cx: 0.5,
      //                 cy: 0.3,
      //                 r: 0.7
      //             },
      //             stops: [
      //                 [0, color],
      //                 [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
      //             ]
      //         };
      //     })
    });

    // Build the chart
    Highcharts.chart({
      loading: loading,
      chart: {
        backgroundColor: 'transparent',
        //@ts-ignore
        plotBackgroundColor: 'transparent',
        //@ts-ignore
        plotBorderWidth: null,
        renderTo: props.targetId,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: `${data.title}`,
        style: { fontWeight: '700', fontSize: '25px' },
        margin: 40
      },
      tooltip: { enabled: false },
      accessibility: {
        point: {
          valueSuffix: `${data.valueSuffix}`,
          valuePrefix: `${data.valuePrefix}`
        }
      },
      // removes HighCharts.com watermark -- added by Glen Turner.
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          size: '60%',
          borderColor: '#ebfbff',
          borderWidth: 5,
          innerSize: '100px',
          allowPointSelect: true,
          cursor: 'pointer',
          crisp: true,
          dataLabels: {
            overflow: 'allow',
            useHTML: true,
            enabled: true,
            formatter: function (a) {
              return `<span style="color: #238DA8; font-size: 0.9rem; margin-right: 7px;">${this.point.name}</span><br/><span style="font-size: 0.8rem; color:black; "><strong>${FormatNumber.toPercent(this.percentage / 100, false)}</strong> ${showValue ? `(${FormatNumber.toMoney(this.point.y || 0)})` : ''}
                            ${isNegative ? `(-${FormatNumber.toMoney(this.point.y || 0)})` : ''}
                            </span>`
            },
          }
        }
      },
      series: data.chartSeriesList
    });
  }, [data, props.targetId])

  return (
    <figure className={style.pieChart}>
      <div id={props.targetId} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}></div>
    </figure>
  );
});

export default PieChart;