import React from 'react';
import { Hero } from '../..';
import { Flex } from '../..';
import style from "./style.module.scss";
import { ParticlesTs } from '../../ParticlesTs';
import { MediaIcons } from '../../../Constants/ParticleTsIcons';
import { HeaderHighlight } from '../..';
import { CurveBg } from '../../../Assets';
import { ReusableStaticImage } from '../..';
import { GradientCanvas } from '../../GradientCanvas';

const Header = (props: { content: any }) => {
    return (
        <Flex column>
            <HeaderHighlight background="#fff" />
            {props.content}
        </Flex>
    )
}

export const LandingSection = (props: { grabber?: string, headerContent: any, image?: any, variant?: 'particle' | 'gradient', hideShadow?: boolean, showOverflow?: boolean }) => {
    const { variant = 'particle' } = props;
    switch (variant) {
        case 'gradient': return (
            <Flex id="media_landing" column className={style.hero_wrapper}>
                <Flex className={style.particle_container}>
                    <GradientCanvas />
                </Flex>
                <Flex className={style.angle}>
                    {/* <GradientCanvas /> */}
                </Flex>
                <Hero
                    showOverflow={props.showOverflow}
                    hideShadow={props.hideShadow}
                    color="#fff"
                    buttonTheme="callToActionWhite"
                    header={<Header content={props.headerContent} />}
                    grabber={props.grabber}
                    height="40vw"
                    image={props.image}
                />
                <div className={style.wave_img_animate}>
                    <ReusableStaticImage width={350} image={CurveBg} />
                </div>
            </Flex>
        )
        case 'particle': default: return (
            <Flex column className={style.hero_wrapper}>
                <Flex className={style.particle_container}>
                    <ParticlesTs direction="top" images={MediaIcons} image={MediaIcons} />
                </Flex>
                {/* <div className={style.box}> </div> */}
                <div className={style.image_bg}></div>
                <Hero
                    hideShadow={props.hideShadow}
                    color="#fff"
                    buttonTheme="callToActionWhite"
                    header={props.headerContent}
                    grabber={props.grabber}
                    height="100%"
                    image={props.image}
                />
                <div className={style.wave_img}>
                    <ReusableStaticImage width={200} image={CurveBg} />
                </div>
            </Flex>
        );
    }
};
