import React, { useEffect, useMemo } from "react";
import { ForumOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Logo } from "..";
import BrokerCheck from "../../Assets/Images/broker_check.png";
import logoPath from "../../Assets/Images/MainLogo.svg";
import { NavGroup, NavGroupItem } from "./NavGroup";
import { ToggleContactModal } from "../../Redux/Actions";
import { Disclosures } from "./Disclosures";
import style from "./style.module.scss";
import { AppState } from "../../Redux/Models";

export const Footer = React.memo(() => {
  const dispatch = useDispatch();

  const {
    loading: bLoading,
    data: bData,
    error: bError,
  } = useSelector((s: AppState) => s.content.businessServices);
  const {
    loading: iLoading,
    data: iData,
    error: iError,
  } = useSelector((s: AppState) => s.content.individualServices);

  const navGroups: NavGroupItem[] = useMemo(
    () => [
      {
        main: { label: "Home", to: "/" },
        items: [
          { label: "Our Commitment", to: "/#our-commitment" },
          { label: "Our Investment Philosophy", to: "/#investment-philosophy" },
          { label: "Explore Our Services", to: "/#explore-services" },
        ],
      },
      {
        main: {
          label: "Services for Individuals",
          to: "/services#indiv-services-header",
        },
        items: [
          ...(iData?.map((d) => ({
            label: d.Service?.Title || "",
            to: `/services#${d.ContentId}`,
          })) || []),
        ],
      },
      {
        main: {
          label: "Business Services",
          to: "/services#biz-services-header",
        },
        items: [
          ...(bData?.map((d) => ({
            label: d.Service?.Title || "",
            to: `/services#${d.ContentId}`,
          })) || []),
        ],
      },
      {
        main: { label: "About Us", to: "/about" },
        items: [
          { label: "Our Vision", to: "/about#our-vision" },
          { label: "Our Principles", to: "/about#our-principles" },
          { label: "Our Team", to: "/about#team-bio" },
        ],
      },
      // {
      //   main: { label: "Portfolio", to: '/' },
      //   items: [
      //     { label: "Black Swan", to: '/' },
      //     { label: "Compounding Interest", to: '/' },
      //     { label: "High Volatility", to: '/' },
      //     { label: "Long Term", to: '/' },
      //     { label: "Covered Calls", to: '/' }
      //   ]
      // },
      // {
      //   main: { label: "Events", to: '/' },
      //   items: [
      //     { label: "Upcoming Events", to: '/' },
      //     { label: "Sponser an Event", to: '/' },
      //   ]
      // },
      // {
      //   main: { label: "Media", to: '/' },
      //   items: [
      //     { label: "Education Resources", to: '/' },
      //     { label: "FAQ", to: '/' }
      //   ]
      // },
    ],
    [bData, iData]
  );

  return (
    <Flex column className={style.wrapper}>
      <Flex column style={{ flex: 1 }} justify="center" align="center">
        <img
          draggable="false"
          style={{ height: 150, width: "auto" }}
          alt="Eagle Harbor Logo"
          src={logoPath}
        />
        <h6 style={{ alignSelf: "center" }} className={style.tagline}>
          <strong className={style.tagline}>Plan • Protect • Prosper</strong>
        </h6>
      </Flex>
      <Flex center column className={style.location_wrapper_mobile}>
        <span>
          2650 N. Military Trail <br />
          Suite 420 Boca Raton, FL 33431
          <br /> 561.576.8280
        </span>
        <span>
          11555 Heron Bay Blvd <br />
          Suite 200 Coral Springs, FL 33067 <br /> 561.576.8282
        </span>
      </Flex>
      <Flex
        style={{ paddingTop: 50, paddingBottom: 50 }}
        row
        justify="center"
        align="flex-start"
      >
        <Flex
          row
          justify="flex-end"
          align="flex-start"
          className={style.navGroupWrapper}
        >
          {navGroups.map((g) => (
            <NavGroup item={g} />
          ))}
        </Flex>
        <Flex
          column
          justify="flex-start"
          align="center"
          className={style.buttonSection}
        >
          <Button
            IconComponent={ForumOutlined}
            containerStyle={{ padding: "10px 30px" }}
            theme="callToActionBlack"
            onClick={() => dispatch(ToggleContactModal(true))}
          >
            Contact Us
          </Button>
          <Flex column align="flex-start" className={style.location_wrapper}>
            <span>
              2650 N. Military Trail <br />
              Suite 420 Boca Raton, FL 33431
              <br /> 561.576.8280
            </span>
            <span>
              11555 Heron Bay Blvd <br />
              Suite 200 Coral Springs, FL 33067 <br /> 561.576.8282
            </span>
          </Flex>
          <img
            alt="brokercheck"
            draggable="false"
            onClick={() =>
              window.open("https://brokercheck.finra.org/", "_blank")
            }
            src={BrokerCheck}
            style={{ width: 100, marginTop: 20, cursor: "pointer" }}
          />
        </Flex>
      </Flex>
      <Disclosures />
    </Flex>
  );
});
