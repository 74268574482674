import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import logoPath from "../../Assets/Images/MainLogo.svg";
import logoPathLight from "../../Assets/Images/MainLogoLight.svg";
import gsap from "gsap";
import { useHistory, useLocation } from "react-router-dom";
import { Flex } from "..";
import style from "./style.module.scss";
import { AppState } from "../../Redux/Models";
import { useSelector } from "react-redux";
import { WindowSizes } from "../../Redux/Models/Utility/WIndowSizes";
import { useAppHeaderTheme } from "../Header/useAppHeaderTheme";
const smallLogoWindowSizes = new Set([
  WindowSizes.smallTablet,
  WindowSizes.tablet,
  WindowSizes.mobile,
  WindowSizes.tinyMobile,
  WindowSizes.smallMobile,
]);

export const Logo = React.memo(
  (props: {
    isScrolling?: boolean;
    size?: number;
    onSizeChange?: (e: any) => any;
    withoutText?: boolean;
    withoutSubText?: boolean;
    selected?: boolean;
  }) => {
    const { size = 100, onSizeChange, withoutText = false } = props;
    const [headerTheme] = useAppHeaderTheme();
    const imgRef = useRef(null);
    const history = useHistory();
    const location = useLocation();
    const [isLarge, setIsLarge] = useState(false);
    const [selected, setSelected] = useState(false);
    const appWindowSize = useSelector((s: AppState) => s.utility.appWindowSize);

    const fontColor = useMemo(() => {
      switch (headerTheme) {
        case "transparent-start":
          return props.isScrolling ? "black" : "white";
        case "primary":
        default:
          return "black";
      }
    }, [headerTheme, props.isScrolling]);

    useEffect(() => {
      if (!onSizeChange) return;
      const isSmallScreen = smallLogoWindowSizes.has(appWindowSize);

      // // eslint-disable-next-line react-hooks/exhaustive-deps
      const logoShrink = () => {
        onSizeChange(false);
        if (imgRef.current)
          gsap.to(imgRef.current, { height: size - 100, duration: 0.2 });
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const logoGrow = () => {
        onSizeChange(true);
        if (imgRef.current)
          gsap.to(imgRef.current, { height: size, duration: 0.2 });
      };

      const scrollEventListener = (e: any) => {
        //Shrink logo
        if (isLarge && window.scrollY >= 25) {
          setIsLarge(false);
          logoShrink();
        }
        //Grow logo
        else if (!isLarge && window.scrollY <= 25) {
          setIsLarge(true);
          logoGrow();
        }
      };

      if (isSmallScreen) {
        setIsLarge(false);
        if (isLarge) logoShrink();
        window.removeEventListener("scroll", (e) => e);
        return () => window.removeEventListener("scroll", scrollEventListener);
      }

      //Shrink logo
      if (isLarge && window.scrollY >= 25 && !isSmallScreen) {
        setIsLarge(false);
        logoShrink();
      }
      //Grow logo
      else if (!isLarge && window.scrollY <= 25 && !isSmallScreen) {
        setIsLarge(true);
        logoGrow();
      }
      // Reset logo to small size
      // else
      window.addEventListener("scroll", scrollEventListener);

      return () => window.removeEventListener("scroll", scrollEventListener);
    }, [isLarge, appWindowSize, onSizeChange, size]);

    useEffect(() => {
      if (location.pathname === "/") {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }, [location.pathname]);

    const handleClick = useCallback(() => history.push("/"), []);

    const logoImage = useMemo(() => {
      const lightLogo = (
        <img
          draggable="false"
          style={{ height: size, width: "auto" }}
          alt="Eagle Harbor Logo"
          ref={imgRef}
          src={logoPathLight}
        />
      );

      const darkLogo = (
        <img
          className={style.logoImage}
          draggable="false"
          style={{ height: size, width: "auto" }}
          alt="Eagle Harbor Logo"
          ref={imgRef}
          src={logoPath}
        />
      );
      switch (headerTheme) {
        case "transparent-start":
          return props.isScrolling ? darkLogo : lightLogo;
        case "primary":
        default:
          return darkLogo;
      }
    }, [headerTheme, props.isScrolling, size]);

    return (
      <div
        onClick={handleClick}
        className={style.logo}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: isLarge ? "center" : "flex-start",
          cursor: "pointer",
        }}
      >
        <Flex column center>
          {logoImage}
          {!!!withoutText && (
            <h3
              style={{
                transition: "200ms",
                margin: 0,
                // marginLeft: 15,
                marginTop: 0,
                marginBottom: 0,
                alignSelf: isLarge ? "inherit" : "center",
                color: fontColor,
              }}
              className={style.logoText}
            >
              {" "}
              <Flex column center>
                <strong
                  className={`${style.logoStrong} ${
                    !!!withoutText && selected && style.selected
                  }`}
                >
                  EAGLE HARBOR
                </strong>
                <strong
                  className={`${style.logoStrong} ${
                    !!!withoutText && selected && style.selected
                  }`}
                >
                  ADVISORS
                </strong>
              </Flex>
            </h3>
          )}
          <h6
            style={{
              transition: "200ms",
              margin: 0,
              marginTop: 0,
              marginBottom: 0,
              alignSelf: "center",
              color: fontColor,
            }}
            className={style.logoText}
          >
            {" "}
            <strong className={style.tagline}>Plan • Protect • Prosper</strong>
          </h6>
        </Flex>
        {/* {!!!withoutText && (
          <h3
            style={{
              transition: "200ms",
              margin: 0,
              marginLeft: 15,
              marginTop: isLarge ? 12 : 0,
              marginBottom: isLarge ? 15 : 0,
              alignSelf: isLarge ? "inherit" : "center",
              color: fontColor,
            }}
            className={style.logoText}
          >
            {" "}
            <strong
              className={`${style.logoStrong} ${
                !!!withoutText && selected && style.selected
              }`}
            >
              EAGLE HARBOR ADVISORS
            </strong>
          </h3>
        )} */}
      </div>
    );
  }
);
