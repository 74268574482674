import React, { useEffect } from 'react';
import style from './style.module.scss';
import { gsap } from 'gsap';

interface ExpansionListProps {
    isMounted?: boolean;
    children?: any;
    data?: any;
    idx?: any;
}


export const ExpansionList = (props: ExpansionListProps) => {
    const { isMounted, children, idx } = props;
    let ref: any = null;

    useEffect(() => {
        gsap.set(ref, { height: 0 })
    }, [ref])

    useEffect(() => {
        if (!isMounted) {
            gsap.to(ref, { duration: 0.3, height: 0 });
        } else {
            gsap.set(ref, { height: 'auto' });
            gsap.from(ref, { duration: 0.3, height: 0 });
        }
    }, [isMounted, ref]);


    return (
        <div id={idx} key={idx} ref={(e) => (ref = e)} className={style.wrapper}>
            <div className={style.scrollSection}>{children}
            </div>
        </div>
    );
};

