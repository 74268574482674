import React from "react";
import Lottie from "react-lottie";
import AnimationData from "../../Assets/BusinessStatistic.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: AnimationData,
};

interface BusinessStatisticsProps {
  height?: number | string;
  width?: number | string;
  size?: number | string;
  speed?: number;
  options?: any;
}

export const BusinessStatistics = (props: BusinessStatisticsProps) => {
  const { size, height = 400, width = 400, speed = 1, options = {} } = props;
  return (
    <Lottie
      options={{ ...defaultOptions, ...options }}
      height={size ? size : height}
      width={size ? size : width}
      speed={speed}
    />
  );
};