import { Flex } from '../..';
import { ReusableStaticImage } from '../..';
import { Schedule } from '../../../Assets/Icons';
import { MoreButton } from '../../MoreButton';
import { ExpansionPanel } from '../ExpansionPanel';
import style from './style.module.scss';

interface EventCellProps {
    data?: any;
}

export const EventCell = (props: EventCellProps) => {
    const { data } = props;
    return (
        <Flex column className={style.wrapper}>
            <Flex className={style.content}>
                <Flex className={style.event_cell_icon} align="flex-start">
                    <ReusableStaticImage width={5} image={Schedule} />
                </Flex>
                <Flex column align="flex-start" justify="space-evenly">
                    <h3>{data.name}</h3>
                    <ExpansionPanel
                        preview={data.preview}
                        description={data.description}
                    />
                    <span className={style.address}>{data.address}</span>
                    <span className={style.datetime}>{data.datetime}</span>
                    <MoreButton margin="0" iconClass={style.buttonIcon} buttonClass={style.registerButton}>Register</MoreButton>
                </Flex>
            </Flex >
        </Flex >
    );
};
