export interface ThunkConstantSet {
  Loading: string;
  Success: string;
  Failed: string;
  Reset?: string;
}

export const BuildThunkConstants = (prefix: string, isOperation?: boolean): ThunkConstantSet => {
  let constants: any = {
    Loading: `${prefix}_LOADING`,
    Success: `${prefix}_SUCCESS`,
    Failed: `${prefix}_FAILED`
  }
  if (isOperation) constants = { ...constants, Reset: `${prefix}_RESET` }
  return constants
}