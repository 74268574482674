import React from 'react';
import { Button, Flex } from '../../../';
import { ReusableStaticImage } from '../../../ReusableStaticImage';
import style from './style.module.scss';
import { HashLink as Link } from 'react-router-hash-link';

interface CardProps {
    data?: any;
    classes?: any;
    id?: string;
    theme?: any;
    scrollTo?: string;
}

export const Card = React.memo((props: CardProps) => {
    const { data, classes, id, theme, scrollTo } = props;

    return (
        <Flex id={id} column className={`${style.card} ${classes}`}>
            <Flex center column className={style.title_wrapper}>
                <ReusableStaticImage style={style.card_image} image={data?.image} width={data?.width} />
                <span>{data?.title}</span>
            </Flex>
            <Flex center>
                <p>{data?.description}</p>
            </Flex>
            <Flex justify="center" align="flex-end" className={style.learn_more}>
                {scrollTo &&
                    <Link smooth to={`/${scrollTo}#${data.id}`}>
                        <Button theme={theme || "orange"} onClick={() => { }}>Learn More</Button>
                    </Link>
                }
            </Flex>
        </Flex>
    );
});
