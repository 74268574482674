import React from 'react';
import { Flex } from '../../Components';
import { BusinessSolutions as BusinesssSolutionsPage } from '../../Components/BusinessSolutionsPage';
import { SEO } from '../../Components/SEO/SEO';
import style from "./style.module.scss";
import { HeaderPageSpacer } from '../../Components/Header/HeaderPageSpacer';


export const BusinessSolutions = () => {
    return (
        <Flex column className={style.page_wrapper}>
            <SEO title={"EH - Business Solutions"} description={"Our business solutions are tailored specifically to the client. Take a look at what Eagle Harbor Advisors can do for you"} />
            <HeaderPageSpacer />
            <BusinesssSolutionsPage />
        </Flex>
    );
};
