import { Flex } from "../../Components"
import { HeaderPageSpacer } from "../../Components/Header/HeaderPageSpacer"
import { useWindowSize } from "../../Hooks"

export const Calendly = () => {
    const { width, height = 0 } = useWindowSize()
    return (
        <Flex style={{ width: '100%' }}>
            <HeaderPageSpacer />
            <iframe title='mytitle' src="https://calendly.com/smartmoneyg?embed_domain=calendly-embed.com&amp;embed_type=Inline&amp;primary_color=844cb0" width={`${width}px`} frameBorder="0" height={`${height * 1.5}px`} />
        </Flex>
    )
}