import * as Actions from '../Constants/EmailConstants';
import { EmailState } from '../Models/Email';

export const EmailReducer = (state: EmailState = new EmailState(), action: any): EmailState => {
  switch (action.type) {
    case Actions.SENDING_EMAIL_LOADING: return { ...state, emailOperation: state.emailOperation.startLoading() }
    case Actions.SENDING_EMAIL_SUCCESS: return { ...state, emailOperation: state.emailOperation.succeeded() }
    case Actions.SENDING_EMAIL_FAILED: return { ...state, emailOperation: state.emailOperation.failed(action.error) }
    default: return state;
  }
}