import React, { useMemo } from 'react';
import { Helmet } from "react-helmet"

export const SEO = React.memo(({ title, description, meta }: { title?: string, description?: string, meta?: any[] }) => {

  const tags = useMemo(() => [
    {
      name: `description`,
      content: description,
    },
    ...(meta?.map(m => ({name: m.Label, content: m.Value})) || [])
  ], [description, meta])
  
  return (
    <Helmet title={title}
      defaultTitle="Eagle Harbor Advisors"
      htmlAttributes={{ lang: "en" }}
      meta={tags}
    />
  )
})