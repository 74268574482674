import React from 'react';
import { Flex } from '../../../Flex';
import style from "./style.module.scss";

interface BannerContentProps {
    title: string;
    content: string;
    color?: string;
    accentColor?: string;
    showBrand?: boolean;
}

export const BannerContent = React.memo((props: BannerContentProps) => {
    const { title, content, color, accentColor, showBrand = true } = props;
    const brand = 'Eagle Harbor Advisors';
    return (
        <Flex center column className={style.section}>
            <h2 style={{ color: accentColor && accentColor }}>
                <strong
                    style={{ color: color ? color : '#2175EA', marginRight: '10px' }}>
                    {showBrand && brand}
                </strong>
                {title}
            </h2>
            <span className={style.content}>{content}</span>
        </Flex>
    );
});
