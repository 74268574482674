import React from 'react'
import { Flex } from '../..'
import { BlackSwan, FinancialSystem } from '../../../Assets';
import StockScrash from '../../../Assets/Images/stock_crash.jpeg';
import CompoundingInterestImage from '../../../Assets/Images/compoundingInterest.jpeg';
import { ContentSection } from '../../ContentSection';
import style from './style.module.scss';

export const ContentSections = (props: any) => {

  const ExampleContent: any[] = [
    {
      reverse: true,
      headerAccent: 'square',
      header: <>Avoid The<br />Black Swan</>,
      type: "white",
      fill: "#0C3F75",
      backgroundColor: 'linear-gradient(#0B3F75, #276cb8)',
      imageBackgroundColor: 'rgba(14,220,175, 0.3)',
      dark: true,
      id: "black-swan",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Orci phasellus egestas tellus rutrum tellus pellentesque eu. Vel pretium lectus quam id leo in vitae turpis. Laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt.",
      src: BlackSwan,
      // onAction: () => { console.log("Black Swan Clicked") }
    },
    {
      reverse: false,
      headerAccent: 'square',
      backgroundColor: 'linear-gradient(#276cb8, #80ffe8)',
      imageBackgroundColor: 'rgba(239,239,239, 0.2)',
      header: <>Power of<br />Compounding Interest</>,
      type: "white",
      dark: true,
      id: "our-approach",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Orci phasellus egestas tellus rutrum tellus pellentesque eu. Vel pretium lectus quam id leo in vitae turpis. Laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt.",
      src: FinancialSystem,
      // onAction: () => { console.log("Our Approach Clicked") }
    }
  ]

  return (
    <Flex className={style.wrapper} column align="center">
      {ExampleContent.map((s, key) => <ContentSection {...s} key={key} />)}
      <Flex column style={{ position: 'relative', height: '60px', width: '100vw', overflow: 'hidden' }}>
        <Flex column align="center" style={{ position: 'absolute', width: '100%', flex: 1, top: 0, bottom: 0, left: 0, right: 0 }}>
          <div style={{ flex: 1, width: 10, background: '#0C3F75', borderRadius: 10 }} />
        </Flex>
        <Flex column style={{ width: '100%', flex: 1 }}>
          <Flex children={null} style={{ flex: 1, width: '100%', background: '#80ffe8' }} />
          <Flex children={null} style={{ flex: 1, width: '100%' }} />
        </Flex>
      </Flex>
    </Flex>
  )
}
