import React from 'react';
import { Flex } from '../..';
import { SectionHeader } from '../../MediaPage/SectionHeader';
import { WebinarCell } from '../WebinarCell';
import { WebinarContent } from '../../../Constants/Content';
import style from './style.module.scss';

export const WebinarSection = () => {
    return (
        <Flex column className={style.wrapper}>
            <Flex>
                <SectionHeader content={<h2>Upcoming <br /> Webinars</h2>} />
            </Flex>
            <Flex wrap center className={style.webinar_cell_wrappper}>
                {WebinarContent.map((w) => <WebinarCell classes={style.webinar_cell} data={w} />)}
            </Flex>
        </Flex>
    );
};
