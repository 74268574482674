import { TrendingFlatOutlined } from '@mui/icons-material';
import React, { useMemo, useState } from 'react'
import { Flex } from '..';
import style from './style.module.scss';

export interface MoreButtonProps {
  hovered?: boolean;
  onClick?: (e?: any) => any;
  children?: any;
  buttonClass?: any;
  iconClass?: any;
  margin?: any;
  scrollTo?: boolean;
}

export const MoreButton = (props: MoreButtonProps) => {
  const { onClick = () => { }, children = "Learn More", buttonClass, iconClass, margin, scrollTo = false } = props;
  const [localHovered, setLocalHovered] = useState(false);
  const isHovered = useMemo(() => localHovered || props.hovered, [localHovered, props.hovered])

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(e);
  };

  const click = (e: any) => {
    if (scrollTo) {
      onClick(e);
    }
  };

  return (
    <button onClick={!scrollTo ? handleClick : click} onMouseEnter={() => setLocalHovered(true)} onMouseLeave={() => setLocalHovered(false)} style={{ margin: `${margin}` ? `${margin}` : '' }} className={`${style.learnMoreButton} ${isHovered ? style.hovered : null}`}>
      <Flex
        justify="space-between"
        align="center"
        className={style.learnMoreContent}
      >
        <span className={buttonClass} style={{ marginRight: 20 }}>{children}</span>
        <TrendingFlatOutlined className={iconClass} style={{ fontSize: 30 }} />
      </Flex>
    </button>
  )
}
