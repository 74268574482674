import React from 'react';
import { Flex } from '..';
import { BannerImageSection } from '.';
import style from './style.module.scss';

interface ContentBannerProps {
    header?: any;
    grabber?: any;
    children?: any;
    backgroundColor?: string;
    id?: string;
    data?: any;
}

export const ContentBanner = (props: ContentBannerProps) => {
    const { header, grabber, children, backgroundColor = '#fff', id, data } = props;
    return (
        <Flex
            className={style.wrapper}
            id={id}
            column
            center
            style={{ backgroundColor: backgroundColor && backgroundColor }}>
            <h2>{header}</h2>
            <Flex className={style.content}>
                {grabber}
            </Flex>
            <Flex className={style.children}>
                {children ? children : <BannerImageSection data={data} />}
            </Flex>
        </Flex>
    );
};
