import React, { useEffect } from "react";
import { useWindowSize } from "../../Hooks";

export const Iframe = (props: any) => {
  const { height, width } = useWindowSize();

  const mediaWidth = () => {
    if (width && width > 1200) {
      return 1200;
    } else {
      return width;
    }
  };

  return (
    <iframe
      style={{
        border: "transparent",
        boxShadow: "rgba(13, 13, 13, 0.1) 0px 0.25rem 0.375rem 0px",
        background: "#fff",
      }}
      id="calcFrame"
      title="rmd"
      src={`${props.url}#view=fitH`}
      width={mediaWidth()}
      allow="autoplay;loop; muted;playsline; encrypted-media"
      height={`${height}` || "1000"}
    ></iframe>
  );
};
