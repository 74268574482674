import React from 'react';
import { Flex } from '../..';
import { HeaderHighlight } from '../..';
import style from "./style.module.scss";

export const ParallaxContent = React.memo(() => {
    return (
        <Flex center column className={style.homeAttributesWrapper}>
            <span className={style.content}>Plan • Protect • Prosper</span>
            <HeaderHighlight classes={style.highlight} />
        </Flex>
    );
});
