import React from 'react';
import Particles from "react-tsparticles";


interface ParticleTsProps {
    children?: any;
    images?: any;
    image?: any;
    direction?: "none" | "top" | "top-right" | "right" | "bottom-right" | "bottom"
}

export const ParticlesTs = (props: ParticleTsProps) => {
    const particlesInit: any = (engine: any) => {
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    }

    const particlesLoaded: any = (container: any) => { }
    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    // position: "50% 50%",
                    // repeat: "no-repeat",
                    // size: 'cover',
                    opacity: 0.1,

                    color: {
                        value: "transparent",
                    },
                },
                zIndex: {
                    value: 1,
                },
                backgroundMask: {
                    composite: "destination-out",
                },
                fpsLimit: 60,
                interactivity: {
                    detectsOn: "canvas",
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onDiv: {
                            selectors: '#repulse-div',
                            enable: false,
                            mode: 'repulse',
                        },
                        onHover: {
                            enable: false,
                            mode: "bubble",
                        },
                        resize: false,
                    },
                    modes: {
                        bubble: {
                            distance: 400,
                            duration: 2,
                            opacity: 0.8,
                            size: 40,
                        },
                        push: {
                            quantity: 2,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                        attract: {
                            distance: 200,
                            maxSpeed: 50,
                            speed: 1,
                            factor: 1,
                        }
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: false,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        direction: props.direction ? props.direction : 'none',
                        enable: true,
                        // outMode: "out",
                        random: false,
                        speed: 1,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            value_area: 500,
                        },
                        value: 2,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    zIndex: {
                        value: 1,
                    },
                    shape: {
                        type: 'image',
                        image: props?.image?.map((i: any) => i),
                        images: props?.images?.map((i: any) => i),
                    },
                    size: {
                        random: true,
                        value: 30,
                    },
                },
                detectRetina: true,
            }}
        />
    );
};
