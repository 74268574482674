import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Flex } from "../../Components";
import { InputField } from "../../Components/Modals/Shared";
import { useWindowSize } from "../../Hooks";
import { SendRiskProfileAction } from "../../Redux/Actions/EmailActions";
import styles from "./style.module.scss";
import { B64toBlob } from "../../Utils/Base64";
import { HeaderPageSpacer } from "../../Components/Header/HeaderPageSpacer";

export const RiskProfiler = React.memo(() => {
  const { width, height = 0 } = useWindowSize();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [sumbissionValid, setSubmissionValid] = useState(false);

  useEffect(() => {
    if (!sumbissionValid) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [sumbissionValid]);

  const handleSubmitClick = () => {
    if (name && email) setSubmissionValid(true);
  };

  return (
    <Flex style={{ width: "100vw", position: "relative" }}>
      {!sumbissionValid && (
        <div className={styles.invalidInfoOverlay}>
          <h2 style={{ padding: "0 1rem", textAlign: "center" }}>
            Fill out your name and email and get started
          </h2>
          <div style={{ maxWidth: 500, width: "90vw" }}>
            <InputField
              onChange={(n: string, v: string) => setName(v)}
              type="text"
              name="name"
              label="Name"
            />
            <InputField
              onChange={(n: string, v: string) => setEmail(v)}
              type="text"
              name="email"
              label="Email"
            />
            <Button
              onClick={handleSubmitClick}
              theme="submit"
              containerStyle={{ marginTop: 40 }}
            >
              Start Risk Profiler
            </Button>
          </div>
        </div>
      )}
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <HeaderPageSpacer />
        <iframe
          title="mytitle"
          src="RiskProfiler/RiskProfiler.html"
          width="100%"
          height={`${height * 1.5}px`}
          frameBorder="0"
        />
      </div>
      <FileFetcher email={email} name={name} />
    </Flex>
  );
});

const FileFetcher = ({ email, name }: { email: string; name: string }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handler = async (event: any) => {
      try {
        const data = JSON.parse(event.data);
        const { profileType, riskScore, values, pdf } = data;
        const b64 = pdf.split(";base64,")[1];
        const blob = await B64toBlob(b64);
        const pdfBlob = new Blob([blob], { type: "application/pdf" });
        if (profileType && riskScore && email && name)
          dispatch(
            SendRiskProfileAction(
              name,
              email,
              riskScore,
              profileType,
              pdfBlob,
              values
            )
          );
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener("message", handler);

    // clean up
    return () => window.removeEventListener("message", handler);
  }, [name, email, dispatch]); // empty array => run only once

  return <div style={{ visibility: "hidden" }} />;
};
