import React, { useState, useEffect, useMemo } from "react";
import { gsap } from "gsap";
import { ExpansionList } from "../ExpansionList/ExpansionList";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import { Flex } from "../../../..";
import style from "./style.module.scss";

interface ExpansionGroupProps {
    header?: any;
    children?: any;
    menuItems?: any;
    onClick?: () => void;
    expanded?: boolean;
    idx?: any;
}

interface SectionHeaderProps {
    header?: any;
    toggleExpanded?: any;
    isExpanded?: boolean;
    idx?: any;
}

export const ExpansionGroup = React.memo((props: ExpansionGroupProps) => {
    const { header, children, idx, expanded } = props;
    return (
        <div
            className={style.sectionWrapper}
            key={idx}
            id={idx}
        >
            <SectionHeader
                header={header}
                toggleExpanded={props.onClick}
                isExpanded={props.expanded}
                idx={idx}
            />
            <ExpansionList children={children} idx={idx} isMounted={expanded} />
        </div>
    );
});


const SectionHeader = (props: SectionHeaderProps) => {
    let [isHovered, toggleHover] = useState(false);

    let expandArrow: any = null;

    const { header, toggleExpanded, isExpanded, idx } =
        props;

    useEffect(() => {
        if (isExpanded && expandArrow) {
            gsap.to(expandArrow, { duration: 0.2, rotation: 180 });
        } else {
            gsap.fromTo(expandArrow, { duration: 0.2, rotation: 180 }, { rotation: 0 });
        }
    }, [isExpanded]);

    return (
        <div
            onClick={toggleExpanded}
            id={idx}
            className={style.sectionHeader}
            onMouseEnter={() => toggleHover(true)}
            onMouseLeave={() => toggleHover(false)}
        >

            <Flex row justify="space-between" align="center">
                <label style={{ color: isExpanded ? '#1364BA' : '#000' }}> {header}</label>
            </Flex>
            {!isExpanded && <AddIcon
                style={{ color: isExpanded ? '#1364BA' : '#000', fontSize: '34px', fontWeight: 600 }}
                className={style.addIcon}
                ref={(r) => (expandArrow = r)}
            />}
            {isExpanded && <RemoveIcon
                style={{ color: isExpanded ? '#1364BA' : '#000', fontSize: '34px', fontWeight: 600 }}
                className={style.addIcon}
                ref={(r) => (expandArrow = r)}
            />}
        </div>
    );
};

