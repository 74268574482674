import {
  AccountBalanceWalletTwoTone,
  MonetizationOnTwoTone,
  PlaylistAddCheckTwoTone,
  DonutSmallTwoTone,
  LocalAtmTwoTone,
  BookTwoTone,
  InsertChartTwoTone,
} from "@mui/icons-material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Flex } from "../..";
import { AppState } from "../../../Redux/Models";
import { CardCarousel, CardItem } from "../../CardComponents";
import { PortraitCardProps } from "../../PortraitCard";
import {
  BusinessOffice,
  PilotsCockpit,
  MedicalProfessional,
  Venue,
  RetirementSponsor,
  Individuals,
} from "../../../Assets";
import { ExitStrategy } from "../../../Assets/Icons";
import { CarouselSlide } from "../../CarouselSlide";
import style from "./style.module.scss";

const ServiceIcons = [
  MonetizationOnTwoTone,
  InsertChartTwoTone,
  DonutSmallTwoTone,
  AccountBalanceWalletTwoTone,
  LocalAtmTwoTone,
  BookTwoTone,
];

const portraitCardItems: PortraitCardProps[] = [
  {
    id: "/business-solutions#eha-business",
    title: "Business Owners",
    src: BusinessOffice,
    headline: "Secure Your Financial Future",
    description:
      "Eagle Harbor Advisors understands the challenges that business owners face. As Independent Advisor, we too are business owners, and we have been providing financial planning for business owners in the South Florida area for nearly four decades. In creating financial plans for entrepreneurs in all types of businesses, we have learned which personal financial issues keep business owners up at night and interrupt their focus on their companies during the day. With our knowledge and experience, we are in a position to offload that stress by becoming partners in the management of any business owner’s long-term financial future.",
  },
  {
    id: "/pilots#hero",
    title: "Aviation Professionals",
    src: PilotsCockpit,
    headline: "Navigating to your financial destination.",
    description:
      "You need to know where you are in order to plan to reach your financial destination. Eagle Harbor Advisors is uniquely equipped to assist Aviation Professionals plan, execute and monitor their financial flight plan. We view our role in this relationship as the invaluable copilot, completely focused on assisting you reach your financial destinations safely and on time. We help you take control of your finances and fully organize your money. We will identify your values, goals, priorities, preferences, as well as potential obstacles.",
  },
  {
    id: "/services#individual-service-section-1",
    title: "Individuals",
    src: Individuals,
    headline: "Expertise Across the Financial Spectrum",
    description:
      "You have spent a lifetime building your wealth. Now it is time to put it to work. Our wealth advisors take a unique, client-centered approach. We build a tailored portfolio based on your investment objectives and risk tolerance. Our advisors have deep expertise across geographies and asset classes, including fixed income, money markets, public equity, commodities, hedge funds and real estate. This comprehensive knowledge allows us to offer you a diversified portfolio that is both resilient and poised for growth, regardless of market conditions.",
  },
  {
    title: "Professional Athletes & Entertainers",
    src: Venue,
    headline: "Your Financial Safety Net",
    description:
      "In the high-stakes world of sports and entertainment, managing your wealth can be as demanding as perfecting your craft. We recognize the challenges that come with sudden wealth, fluctuating income streams, and the uncertainty of career longevity. Our mission is to provide comprehensive advice, education, and guidance to navigate these complexities. With Eagle Harbor Advisors, you gain more than a financial advisor; you acquire a strategic partner dedicated to your long-term prosperity. The cornerstone of our approach for athletes and entertainers is a strong emphasis on asset protection and preservation. We understand that your financial picture is unique, with opportunities and risks not found in other professions. Our strategies are designed to protect what you've earned, ensuring your wealth remains secure.",
  },
  {
    id: "/services#business-service-section-2",
    title: "Retirement Plan Sponsors",
    src: RetirementSponsor,
    headline: "Corporate Retirement Plan Design",
    description:
      "Having a framework to support informed decision making aligned with fiduciary roles and responsibilities is critical to meeting regulatory requirements and fulfilling participant expectations. Your organization has specific goals, and your corporate retirement plan should be responsive to those goals. Key considerations, such as corporate structure, business objectives, and cash flow are factors in the decisions related to the appropriate corporate retirement plan design for the business.",
  },
  {
    title: "Medical Professionals",
    src: MedicalProfessional,
    headline: "Elevate Your Financial Well-being",
    description:
      "At Eagle Harbor Advisors, we understand the unique financial challenges and opportunities faced by medical professionals. Whether you're navigating the early stages of your career, expanding your practice, or contemplating retirement, our specialized financial advisory services are tailored to ensure your financial health is as robust as your patients'. Our investment philosophy is grounded in capital preservation and the power of effective compounding. We recognize that your hard-earned income is pivotal not just for your present lifestyle but as a cornerstone for a secure future. That's why we emphasize protecting your capital against the unpredictable waves of the market, ensuring your peace of mind.",
  },
];

export const ExploreServices = React.memo((props: any) => {
  const {
    loading: bLoading,
    data: bData,
    error: bError,
  } = useSelector((s: AppState) => s.content.businessServices);
  const {
    loading: iLoading,
    data: iData,
    error: iError,
  } = useSelector((s: AppState) => s.content.individualServices);
  const cardItems: CardItem[] = useMemo(() => {
    if (!iData?.length || !bData?.length) return [];
    let counter = 0;
    return [...(iData || []), ...(bData || [])].map((s) => {
      const Icon = ServiceIcons[counter];
      counter = counter + 1 >= ServiceIcons.length ? 0 : counter + 1;
      return {
        title: s.Service?.TwoWordTitle,
        id: s.ContentId,
        subText: s.Service?.Blurb,
        onLearnMore: () => console.log(`Clicked ${s.Service?.TwoWordTitle}`),
        IconComponent: Icon,
      };
    });
  }, [iData, bData]);

  return (
    <Flex id="explore-services" column align="center" className={style.wrapper}>
      <h2 className={style.sectionHeader} style={{ marginBottom: 20 }}>
        Who we serve
      </h2>
      <div className={style.content}>
        <CardCarousel cardItems={portraitCardItems} />
        {/* <div className={style.carouselDesktopContainer}></div>
        <div className={style.carouselMobileContainer}>
          <CarouselSlide data={portraitCardItems} />
        </div> */}
      </div>
    </Flex>
  );
});
