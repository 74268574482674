import { HomeOutlined, HomeWorkOutlined } from '@mui/icons-material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Flex } from '../../Components';
import { NotFoundLottie } from '../../Components/LottieFIles';
import style from './style.module.scss';

export const NotFound = (props: any) => {
  const history = useHistory()
  return (
    <Flex column center style={{height: '100vh'}}>
      <Flex column center style={{height: 'calc(100vh - 60px)'}}>
        <NotFoundLottie size={500} />
        <Flex center column>
          <h3 style={{margin: '20px 10px'}}>Looks like this page doesn't exist.</h3>
          <Button labelStyle={{margin: '0 5', fontSize: 20}} IconComponent={HomeWorkOutlined} containerStyle={{padding: '10px 20px'}} theme="callToActionBlack" onClick={() => history.push('/')}>Back to Home</Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
