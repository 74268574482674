import * as Actions from '../Constants/ContentContants';
import { ContentState } from '../Models/Content';

const {
  GetBusinessServices,
  GetIndividualServices,
  GetAboutLandingSection,
  GetAboutSectionOne,
  GetAboutTeam,
  GetHomeLandingSection,
  GetHomeSectionOne,
  GetHomeSectionTwo,
  GetArticles,
  GetMainMetaTags
} = Actions;

export const ContentReducer = (state: ContentState = new ContentState(), action: any): ContentState => {
  switch (action.type) {

    // COLLECTION TYPES 

    // Get Individual Services from the CMS
    case GetIndividualServices.Loading: return { ...state, individualServices: state.individualServices.startLoading() }
    case GetIndividualServices.Success: return { ...state, individualServices: state.individualServices.succeeded(action.data) }
    case GetIndividualServices.Failed: return { ...state, individualServices: state.individualServices.failed(action.error) }

    // Get Business Services from the CMS
    case GetBusinessServices.Loading: return { ...state, businessServices: state.businessServices.startLoading() }
    case GetBusinessServices.Success: return { ...state, businessServices: state.businessServices.succeeded(action.data) }
    case GetBusinessServices.Failed: return { ...state, businessServices: state.businessServices.failed(action.error) }

    // Get Articles from the CMS
    case GetArticles.Loading: return { ...state, articles: state.articles.startLoading() }
    case GetArticles.Success: return { ...state, articles: state.articles.succeeded(action.data) }
    case GetArticles.Failed: return { ...state, articles: state.articles.failed(action.error) }

    // SINGLE TYPES

    // Get About Landing Section from CMS -- added by Glen Turner
    case GetAboutLandingSection.Loading: return { ...state, aboutLandingSection: state.aboutLandingSection.startLoading() }
    case GetAboutLandingSection.Success: return { ...state, aboutLandingSection: state.aboutLandingSection.succeeded(action.data) }
    case GetAboutLandingSection.Failed: return { ...state, aboutLandingSection: state.aboutLandingSection.failed(action.error) }

    // Get About Section One -- added by Glen Turner.
    case GetAboutSectionOne.Loading: return { ...state, aboutSectionOne: state.aboutSectionOne.startLoading() }
    case GetAboutSectionOne.Success: return { ...state, aboutSectionOne: state.aboutSectionOne.succeeded(action.data) }
    case GetAboutSectionOne.Failed: return { ...state, aboutSectionOne: state.aboutSectionOne.failed(action.error) }

    // Get About Team -- added by Glen Turner.
    case GetAboutTeam.Loading: return { ...state, aboutTeam: state.aboutTeam.startLoading() }
    case GetAboutTeam.Success: return { ...state, aboutTeam: state.aboutTeam.succeeded(action.data) }
    case GetAboutTeam.Failed: return { ...state, aboutTeam: state.aboutTeam.failed(action.error) }

    // Get Home Landing Section -- added by Glen Turner.
    case GetHomeLandingSection.Loading: return { ...state, homeLandingSection: state.homeLandingSection.startLoading() }
    case GetHomeLandingSection.Success: return { ...state, homeLandingSection: state.homeLandingSection.succeeded(action.data) }
    case GetHomeLandingSection.Failed: return { ...state, homeLandingSection: state.homeLandingSection.failed(action.error) }

    // Get Home Section One -- added by Glen Turner.
    case GetHomeSectionOne.Loading: return { ...state, homeSectionOne: state.homeSectionOne.startLoading() }
    case GetHomeSectionOne.Success: return { ...state, homeSectionOne: state.homeSectionOne.succeeded(action.data) }
    case GetHomeSectionOne.Failed: return { ...state, homeSectionOne: state.homeSectionOne.failed(action.error) }

    // Get Home Section Two -- added by Glen Turner.
    case GetHomeSectionTwo.Loading: return { ...state, homeSectionTwo: state.homeSectionTwo.startLoading() }
    case GetHomeSectionTwo.Success: return { ...state, homeSectionTwo: state.homeSectionTwo.succeeded(action.data) }
    case GetHomeSectionTwo.Failed: return { ...state, homeSectionTwo: state.homeSectionTwo.failed(action.error) }

    case GetMainMetaTags.Loading: return { ...state, metaDataTags: state.metaDataTags.startLoading() }
    case GetMainMetaTags.Success: return { ...state, metaDataTags: state.metaDataTags.succeeded(action.data) }
    case GetMainMetaTags.Failed: return { ...state, metaDataTags: state.metaDataTags.failed(action.error) }
    default: return state;
  }
}