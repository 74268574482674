import React, { memo, useCallback } from 'react';
import { Flex, DoubleBlockContent } from '..';
import style from './style.module.scss'
import { HeaderHighlight } from '..';
import { WaveWrapper } from '../WaveWrapper';
import { useEffect, useState } from 'react';
import SR from 'scrollreveal';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
//@ts-ignore
import { Fade } from 'react-reveal';

export interface WaveContentSectionProps {
    reverse?: boolean;
    flipped?: boolean;
    header: string;
    content: string;
    src?: string | null;
    onAction?: () => any;
    buttonLabel?: string;
    allowOverflow?: boolean;
    fill?: string;
    gradientFill?: { topColor: string, bottomColor: string },
    imageBackgroundColor?: string;
    foregroundBackgroundColor?: string;
    extraContentBackground?: string;
    dropShadowColor?: string;
    extraContent?: () => any;
    id?: any;
}

export const WaveContentSection = memo((props: WaveContentSectionProps) => {

    const {
        dropShadowColor,
        header,
        content,
        reverse,
        src,
        fill = "#2A93D1",
        gradientFill,
        flipped,
        imageBackgroundColor,
        foregroundBackgroundColor,
        allowOverflow,
        id
    } = props;

    const [expanded, setExpanded] = useState(false);
    const handleExpanded = useCallback(() => setExpanded(!expanded), [expanded])

    return (
        <Flex column id={id} style={{ width: '100%' }}>
            <WaveWrapper reverse={reverse} id={id} gradientFill={gradientFill} fill={fill} flipped={flipped}>
                <Flex column align="center">
                    <Flex row align="center" className={`${style.contentSection} ${!!reverse && style.contentReverse}`}>
                        <Flex style={{ flex: 1 }}>
                            <DoubleBlockContent allowOverflow={allowOverflow} leanLeft={reverse} dropShadowColor={dropShadowColor} background={imageBackgroundColor} imageBackgroundColor={imageBackgroundColor} foregroundBackgroundColor={foregroundBackgroundColor} type={foregroundBackgroundColor ? "double" : "native"} src={src} />
                        </Flex>
                        <Flex className={style.textGroup}>
                            <Fade distance={'50%'} duration={500} left={props.reverse} right={!props.reverse}>
                                <Flex className={`copy-section ${style.textGroup}`} column justify="center">
                                    <HeaderHighlight classes={style.headerHighlight} background='#fff' />
                                    <h2>{header}</h2>
                                    <p>{content}</p>
                                </Flex>
                            </Fade>
                        </Flex>
                    </Flex>
                    {props.extraContent &&
                        <Flex onClick={handleExpanded} center style={{ width: '200px', background: 'white', borderRadius: 10, boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.4)', padding: '10px 25px', position: "relative", marginBottom: 30 }}>
                            <h4 style={{ color: 'black', marginRight: 5 }}>{expanded ? 'Collapse' : 'Learn More'}</h4>
                            {expanded ? <KeyboardArrowUpRounded style={{ position: 'absolute', right: '10px', color: 'black' }} /> : <KeyboardArrowDownRounded style={{ position: 'absolute', right: '10px', color: 'black' }} />}
                        </Flex>}
                </Flex>
            </WaveWrapper>
            {expanded && props.extraContent && <div className={style.extraContent} style={{ background: props.extraContentBackground || undefined }}>{props.extraContent()}</div>}
        </Flex>
    )
})