import React from 'react'
import { Flex } from '../../Components';
import { SEO } from '../../Components/SEO/SEO';
import { LandingSection } from '../../Components/ServicesPage';
import { ServicesSection } from '../../Components/ServicesPage/ServicesSection';
import style from './style.module.scss';
import { HeaderPageSpacer } from '../../Components/Header/HeaderPageSpacer';

export const Services = (props: any) => {
  return (
    <Flex column className={style.wrapper}>
      <SEO title={"EH - Services"} description={"Browse the financial services that we offer, both for businesses and individuals"} />
      <HeaderPageSpacer />
      <LandingSection />
      <ServicesSection />
    </Flex>
  )
}
