import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

type AppHeaderTheme = 'primary' | 'transparent-start'

export const HeaderThemeAtom = atom<AppHeaderTheme>('primary')

export const useAppHeaderTheme = () => useAtom(HeaderThemeAtom)

export const useAppHeaderThemeSetter = (theme: AppHeaderTheme): void => {
    const [_, setAppHeaderTheme] = useAppHeaderTheme()

    useEffect(() => {
        setAppHeaderTheme(theme)
        return () => setAppHeaderTheme('primary')
    }, [theme, setAppHeaderTheme])
}
