import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex } from "../../Flex";
import { ToggleAppointmentModal } from '../../../Redux/Actions';
import { AppState } from '../../../Redux/Models';
import { AnimatedModal } from "../AnimatedModal";
import { CloseRounded } from "@mui/icons-material";
import { CalendlyInlineWidget } from "../../CalendlyInlineWidget";

export interface AppointmentModalProps {
    data: any;
    checked?: boolean;
}

export const AppointmentModal = React.memo((props: AppointmentModalProps) => {
    const dispatch = useDispatch();
    const isOpen = useSelector((s: AppState) => s.utility.toggleAppointmentModal);

    return (
        <AnimatedModal
            open={isOpen}
            onClose={() => dispatch(ToggleAppointmentModal(false))}
        >
            <Flex justify="flex-end" align="center">
                <div onClick={() => dispatch(ToggleAppointmentModal(false))}>
                    <CloseRounded style={{ color: "#000000", cursor: 'pointer', fontSize: '40px' }} />
                </div>
            </Flex>
            <CalendlyInlineWidget />
        </AnimatedModal >
    );
});
