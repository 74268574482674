export const baseUrl = 'https://eagle-harbor-api.herokuapp.com';
// export const baseUrl = 'http://localhost:8080';

const Urls = {
  contactEmail: () => `${baseUrl}/contact`,
  setAppointmentEmail: () => `${baseUrl}/set-appointment`,
  submitRiskProfile: () => `${baseUrl}/submit-risk-profiler`,
  pdfExample: () => `${baseUrl}/pdf-example`
}

export default Urls