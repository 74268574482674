import { BuildThunkConstants } from "./ConstantHelpers";

// COLLECTION TYPES
export const GetBusinessServices = BuildThunkConstants('GET_BUSINESS_SERVICES');
export const GetIndividualServices = BuildThunkConstants('GET_INDIVIDUAL_SERVICES');
export const GetArticles = BuildThunkConstants('GET_ARTICLES');

// SINGLE TYPES
export const GetAboutLandingSection = BuildThunkConstants('GET_ABOUT_LANDING_SECTION');
export const GetAboutSectionOne = BuildThunkConstants('GET_ABOUT_SECTION_ONE');
export const GetAboutTeam = BuildThunkConstants('GET_ABOUT_TEAM');
export const GetHomeLandingSection = BuildThunkConstants('GET_HOME_LANDING_SECTION');
export const GetHomeSectionOne = BuildThunkConstants('GET_HOME_SECTION_ONE');
export const GetHomeSectionTwo = BuildThunkConstants('GET_HOME_SECTION_TWO');
export const GetMainMetaTags = BuildThunkConstants('GET_MAIN_META_TAGS');