import React from 'react';
import { ParallaxWrapper } from '../../../ParallaxWrapper';
import style from './style.module.scss';

interface BannerWithParrallaxProps {
    children: React.ReactNode;
    image: string;
}

export const BannerWithParrallax = React.memo((props: BannerWithParrallaxProps) => {
    return (
        <>
            <ParallaxWrapper
                height="65vh"
                classes={style.parallax_wrapper}
                src={props.image}
                children={props.children}
            />
        </>
    );
});
