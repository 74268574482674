
import React from 'react';
import style from './style.module.scss';
import { Flex } from '..';

export const Disclosures = () => {
    return (
        <Flex column center className={style.disclosure_wrapper}>
            <p>Check the background of your financial professional on FINRA's
                <a className={style.summary_pdf} href="https://brokercheck.finra.org/" rel="noreferrer" target="_blank">BrokerCheck.</a>
            </p>
            <p>The content is developed from sources believed to be providing accurate information. The information in this material is not intended as tax or legal advice. Please consult legal or tax professionals for specific information regarding your individual situation.
            </p>
            <p>
                Securities offered through Kestra Investment Services, LLC (Kestra IS), member <a href="https://www.finra.org/#/" rel="noreferrer" target="_blank">FINRA/SIPC.</a> Investment advisory services offered through Kestra Private Wealth Services, LLC.  Eagle Harbor Advisors is a member firm of  Kestra Private Wealth Services, LLC, an affiliate of Kestra IS.  Eagle Harbor Advisors and Kestra IS are not affiliated. Neither Kestra IS nor Kestra Private Wealth Services provide legal or tax advice.
            </p>
            <p>
                Fidelity Investments and Fidelity Institutional® (together "Fidelity") is an independent company unaffiliated with Kestra and Eagle Harbor Advisors. Fidelity is a service provider to both. There is no form of legal partnership, agency affiliation, or similar relationship between your financial advisor and Fidelity, nor is such a relationship created or implied by the information herein. Fidelity has not been involved with the preparation of the content supplied by Eagle Harbor Advisors and does not guarantee, or assume any responsibility for, its content. Fidelity is a registered service mark of FMR LLC. Fidelity Institutional® provides clearing, custody, or other brokerage services through National Financial Services LLC or Fidelity Brokerage Services, LLC members NYSE, SIPC. 1027492.1.0
            </p>
            <p>
                This site is published for residents of the United States only. Registered representatives of Kestra Investment Services, LLC and investment adviser representatives of Kestra Private Wealth Services, LLC may only conduct business with residents of the states and jurisdictions in which they are properly registered. Therefore, a response to a request for information may be delayed. Not all of the products and services referenced on this site are available in every state and through every representative or advisor listed. For additional information, please contact Kestra Investment Services, LLC Compliance Department at 844-5-KESTRA (844-553-7872).
                <a className={style.summary_pdf} href="https://www.kestrafinancial.com/disclosures" target="_blank" rel="noreferrer">Investor Disclosures</a>
            </p>
            <p>Certified Financial Planner Board of
                Standards Center for Financial Planning, Inc. owns and licenses the certification marks CFP®, CERTIFIED FINANCIAL PLANNER™, and CFP® (with plaque design) in the United States to Certified Financial Planner Board of Standards, Inc., which authorizes individuals who successfully complete the organization’s initial and ongoing certification requirements to use the certification marks. No investment advice, investment advisory products, securities, or recommendations can assure a gain or prevent a loss in a declining market. There is no guarantee that any investment strategy will be successful or will achieve their stated investment objective.</p>
        </Flex>
    );
};
