import React, { useCallback, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import {
  Animator,
  ScrollPage,
  Animation,
} from "../../Components/ReactScrollMotionMaster/src";
import style from "./style.module.scss";
import {
  VolumeOffRounded,
  VolumeMuteRounded,
  ReplayRounded,
} from "@mui/icons-material";
//@ts-ignore
import { useSelector } from "react-redux";
import { AppState } from "../../Redux/Models";
import { WindowSizes } from "../../Redux/Models/Utility/WIndowSizes";
import { Flex } from "../../Components/Flex";

const VIDEO_URL = "https://www.youtube.com/watch?v=YVUCHR6RuFA=2s";

const YouTubeConfig = {
  youtube: {
    playerVars: {
      modestbranding: 1,
      controls: 0,
      disablekb: 1,
      fs: 0,
      rel: 0,
      showinfo: 0,
    },
  },
};

const ScaleIn = () =>
  ({
    in: {
      style: {
        // `p` is number (0~1)
        // When just before this page appear, `p` will be 0
        // When this page filled your screen, `p` will be 1
      },
    },
    out: {
      style: {
        // `p` is number (0~1)
        // When this page filled your screen, `p` will be 0
        // When just after this page disappear, `p` will be 1
        transform: (p) =>
          `scale(${p < 0.45 ? 1.1 - p : 0.65}) translateY(-${
            (window.innerHeight - 50) * p
          }px)`,
      },
    },
  } as Animation);

const windowSizeSelector = (s: AppState) => s.utility.appWindowSize;

export const LandingSection = React.memo(() => {
  const ref: React.LegacyRef<ReactPlayer> = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [hasUnmutedBefore, setHasUnmutedBefore] = useState(false);
  const [muted, setMuted] = useState(true);
  const appSize = useSelector(windowSizeSelector);

  const soundIcon = useMemo(() => {
    return muted ? (
      <VolumeMuteRounded className={style.videoControlIcon} />
    ) : (
      <VolumeOffRounded className={style.videoControlIcon} />
    );
  }, [muted]);

  const handleReplay = useCallback(() => ref?.current?.seekTo(0), [ref]);

  const handleSoundToggle = useCallback(() => {
    setMuted(!muted);
    if (!hasUnmutedBefore) {
      setHasUnmutedBefore(true);
      handleReplay();
    }
  }, [muted, setMuted, handleReplay, hasUnmutedBefore]);

  const handleLoaded = useCallback(() => setLoaded(true), []);

  const videoSection = useMemo(
    () => (
      <div className={style.videoSection}>
        <Flex className={style.videoWrapper}>
          <ReactPlayer
            ref={ref}
            style={{ visibility: loaded ? "visible" : "hidden" }}
            className={style.video}
            width="100%"
            onStart={handleLoaded}
            height="100%"
            loop={true}
            muted={muted}
            playsinline={true}
            controls={false}
            stopOnUnmount={false}
            url={VIDEO_URL}
            playing={true}
            config={YouTubeConfig}
          />
        </Flex>
        <div className={style.videoOverlay}>
          <div className={style.videoPlayerControls}>
            <div onClick={handleReplay} className={style.videoControlButton}>
              <ReplayRounded className={style.videoControlIcon} />
            </div>
            <div
              onClick={handleSoundToggle}
              className={style.videoControlButton}
            >
              {soundIcon}
            </div>
          </div>
        </div>
      </div>
    ),
    [handleSoundToggle, loaded, muted, soundIcon]
  );

  const animatedVideo = useMemo(() => {
    return (
      <ScrollPage style={{ height: "90vh", overflow: "visible", zIndex: 2 }}>
        <Animator animation={ScaleIn()}>{videoSection}</Animator>
      </ScrollPage>
    );
  }, [videoSection]);

  const videoWrapper = useMemo(() => {
    switch (appSize) {
      case WindowSizes.regular:
        return animatedVideo;
      case WindowSizes.smallTablet:
        return videoSection;
      case WindowSizes.mobile:
        return videoSection;
      case WindowSizes.smallMobile:
        return videoSection;
      case WindowSizes.tinyMobile:
        return videoSection;
      default:
        return animatedVideo;
    }
  }, [animatedVideo, appSize, videoSection]);

  const headerWrapperMarginStyle = useMemo(() => {
    switch (appSize) {
      case WindowSizes.regular:
        return "";
      case WindowSizes.smallTablet:
        return style.headerWrapperMarginMobile;
      case WindowSizes.mobile:
        return style.headerWrapperMarginMobile;
      case WindowSizes.smallMobile:
        return style.headerWrapperMarginMobile;
      case WindowSizes.tinyMobile:
        return style.headerWrapperMarginMobile;
      default:
        return "";
    }
  }, [appSize]);

  return (
    <>
      {videoWrapper}
      <div className={`${style.headerWrapper} ${headerWrapperMarginStyle}`}>
        <h1>Our Philosophy</h1>
        <div className={style.headerTextDivider} />
        <h3>Mitigation of risk. Effective compounding. Reliable income.</h3>
      </div>
    </>
  );
});
