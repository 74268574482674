import React from "react";
import { Flex } from "../..";
import style from "./style.module.scss";

export const ParallaxContent = () => {
  return (
    <Flex center column className={style.section} id="eha-business">
      <h2>Business Solutions</h2>
      <span className={style.content}>
        As your business evolves, having a supportive team behind you is
        essential.
      </span>
    </Flex>
  );
};
