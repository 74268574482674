import React from "react";
import Lottie from "react-lottie";
import AnimationData from "../../Assets/Animated404.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: AnimationData,
};

interface NotFoundLottieProps {
  height?: number | string;
  width?: number | string;
  size?: number | string;
  speed?: number;
  options?: any;
}

export const NotFoundLottie = (props: NotFoundLottieProps) => {
  const { size, height = 400, width = 400, speed = 1, options = {} } = props;
  return (
    <Lottie
      options={{ ...defaultOptions, ...options }}
      height={size ? size : height}
      width={size ? size : width}
      speed={speed}
    />
  );
};