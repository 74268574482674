import { Flex, DoubleBlockContent } from '..';
import { MoreButton } from '../MoreButton';
import style from './style.module.scss'
import { HeaderHighlight } from '..';
import { WaveContentSection } from './WaveContentSection';
import React, { useCallback, useState } from 'react';
import { SectionHeader } from '../MediaPage/SectionHeader/SectionHeader';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { HashLink as Link } from 'react-router-hash-link';
//@ts-ignore
import { Fade } from 'react-reveal';

export interface ContentSectionProps {
  reverse?: boolean;
  header: any;
  headerAccent?: 'line' | 'square';
  content: any;
  src?: string | null;
  onAction?: () => any;
  buttonClass?: any;
  iconClass?: any;
  buttonLabel?: string;
  imageBackgroundColor?: string;
  foregroundBackgroundColor?: string;
  type?: 'white' | 'wave' | 'wave-flipped' | any;
  imageType?: 'native' | 'double' | any;
  fill?: any;
  backgroundColor?: string;
  dropShadowColor?: string;
  paddingFirst?: boolean;
  allowOverflow?: boolean;
  dark?: boolean;
  extraContentBackground?: string;
  extraContent?: () => any;
  id?: any;
  scrollTo?: string;
  scrollId?: string;
  contentVariant?: 'string' | 'jsx';
  headerVariant?: 'string' | 'jsx';
}

export const HeaderSection = React.memo(({ headerAccent = 'line', children, variant = 'string' }: { headerAccent?: 'square' | 'line', children: any, variant?: 'string' | 'jsx' }) => {
  switch (headerAccent) {
    case 'square': return (
      <SectionHeader content={variant === 'string' ? <h2 style={{ margin: 0 }}>{children}</h2> : children} />
    )
    case 'line': default: return (
      <>
        <HeaderHighlight classes={style.headerHighlight} />
        {variant === 'string' ? <h2>{children}</h2> : children}
      </>
    )
  }
})

const WhiteContentSection = React.memo((props: ContentSectionProps) => {
  const { header, headerAccent = "line", content, onAction, reverse, src, buttonClass, iconClass, id, buttonLabel, dark, backgroundColor, scrollId, scrollTo, headerVariant = 'string', contentVariant = 'string' } = props;

  const [expanded, setExpanded] = useState(false);

  const handleExpanded = useCallback(() => setExpanded(!expanded), [expanded])

  return (
    <Flex id={id}>
      <Flex column justify="center" align="center" row style={{ background: backgroundColor, paddingTop: 30, paddingBottom: 30, margin: 0 }}>
        <Flex align="center" className={`${style.contentSection} ${!!reverse && style.contentReverse}`}>
          <Flex style={{ flex: 1 }}>
            <DoubleBlockContent src={src} {...props} leanLeft={reverse} />
          </Flex>
          <Flex style={{ flex: 1 }}>
            <Fade distance={'50%'} duration={500} left={props.reverse} right={!props.reverse}>
              <Flex style={dark ? { color: 'white', flex: 1 } : { flex: 1 }} className={`copy-section-${props.reverse ? 'left' : 'right'} ${style.copySection}`} column justify="center">
                <HeaderSection variant={headerVariant} headerAccent={headerAccent}>
                  {header}
                </HeaderSection>
                {contentVariant === 'string' ? <p>{content}</p> : content}
                {onAction && <MoreButton iconClass={iconClass} buttonClass={buttonClass} onClick={onAction}>{buttonLabel}</MoreButton>}
                {scrollTo && scrollId &&
                  <Link smooth to={`${scrollTo}#${scrollId}`}>
                    <MoreButton scrollTo={true} iconClass={iconClass} buttonClass={buttonClass} onClick={(e) => { }}> {buttonLabel}</MoreButton>
                  </Link>
                }
              </Flex>
            </Fade>
          </Flex>
        </Flex>
        {props.extraContent &&
          <Flex onClick={handleExpanded} center style={{ width: '200px', background: 'white', borderRadius: 10, boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.4)', padding: '10px 25px', position: "relative" }}>
            <h4 style={{ color: 'black', marginRight: 5 }}>{expanded ? 'Collapse' : 'Learn More'}</h4>
            {expanded ? <KeyboardArrowUpRounded style={{ position: 'absolute', right: '10px', color: 'black' }} /> : <KeyboardArrowDownRounded style={{ position: 'absolute', right: '10px', color: 'black' }} />}
          </Flex>}
        {expanded && props.extraContent && <div className={style.extraContent} style={{ background: props.extraContentBackground || undefined }}>{props.extraContent()}</div>}
      </Flex>
    </Flex >
  )
})

export const ContentSection = React.memo((props: ContentSectionProps) => {

  const SectionType = useCallback((props: ContentSectionProps) => {
    switch (props.type) {
      case 'white': return <WhiteContentSection {...props} />
      case 'wave': return <WaveContentSection {...props} />
      case 'wave-flipped': return <WaveContentSection {...props} flipped />
      default:
        return <WhiteContentSection {...props} />
    }
  }, [props.type])

  return (
    <>
      <SectionType {...props} />
    </>
  )
})