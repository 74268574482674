import React from 'react';
import { Button, Flex } from '../..';
import { ReusableStaticImage } from '../..';
import style from './style.module.scss';
import { HashLink as Link } from 'react-router-hash-link';

interface SolutionsCardProps {
    data?: any;
    classes?: any;
    id?: string;
}

export const SolutionsCard = (props: SolutionsCardProps) => {
    const { data, classes, id } = props;

    return (
        <Flex id={id} column className={`${style.card} ${classes}`}>
            <Flex center column className={style.title_wrapper}>
                <ReusableStaticImage style={style.card_image} image={data?.image} width={data?.width} />
                <span>{data?.title}</span>
            </Flex>
            <Flex center>
                <p>{data?.description}</p>
            </Flex>
            <Flex justify="center" align="flex-end" className={style.learn_more}>
                <Link smooth to={`/business-solutions#${data.id}`}>
                    <Button theme="orange" onClick={() => { }}>Learn More</Button>
                </Link>
            </Flex>
        </Flex>
    );
};
