import React, { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Flex, ReusableStaticImage } from '..';
import { Check } from '../../Assets/Icons';
import style from './style.module.scss';

export const BulletItem = memo((props: { content: any, style?: any, variant?: 'string' | 'jsx' }) => {
  const { content, variant = 'string' } = props;
  return (
    <Flex wrap className={style.bullet_item_wrapper}>
      <Flex className={style.bullet_item} style={props.style}>
        <ReusableStaticImage style={style.check_icon} width={1.5} margin="0 15px 0 0" image={Check} />
        {variant === 'string' ? <ReactMarkdown children={content} /> : content}
      </Flex>
    </Flex>
  )
}); 