import { NonProdEnvironments, ResolveEnvironment } from "./EnvironmentHelpers"

export interface VariantFlags {
  showUpcoming: boolean,
  trackAnalytics: boolean,
  showLockScreen: boolean
}

const production: VariantFlags = {
  showUpcoming: false,
  trackAnalytics: true,
  showLockScreen: false
}

const nonProduction: VariantFlags = {
  showUpcoming: true,
  trackAnalytics: false,
  showLockScreen: true
}

export const GetVariant = () => {
  const environment = ResolveEnvironment();

  if (environment === 'production') return production;
  if (NonProdEnvironments.has(environment)) return nonProduction;
  return production;
}