import React from 'react';
import { Flex } from "../../Components/Flex";
import { Hero } from '../../Components/MarketingPages/Pilots/Hero';
import { CardSection } from '../../Components/MarketingPages';
import { ContentBanner } from '../../Components';
import { ContentWithBulletPoints } from '../../Components/MarketingPages/Shared/Sections';
import { PilotSolutions } from '../../Constants/Content';
import { PilotsContent, PilotsBulletPoints } from '../../Constants/Content';
import { SixSteps } from '../../Components/MarketingPages/Pilots/SixSteps';
import { SEO } from '../../Components/SEO/SEO';
import style from './style.module.scss';
import { HeaderPageSpacer } from '../../Components/Header/HeaderPageSpacer';

export const Pilots = () => {
    return (
        <Flex column className={style.page_wrapper}>
            <SEO title={"EH - Pilots"} description={"Our business solutions are tailored specifically to pilots and aviation professional."} />
            <HeaderPageSpacer />
            <Hero />
            <CardSection
                title='Aviation professionals face unique challenges when it comes to financial planning'
                data={PilotSolutions}
            />
            <ContentBanner
                header={<span style={{ color: '#fff' }}>Navigating to your financial destination.</span>}
                backgroundColor="#2A93D1"
                children={<ContentWithBulletPoints points={PilotsBulletPoints} content={PilotsContent} />}
            />
            <SixSteps />
        </Flex>
    );
};
