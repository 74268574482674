import * as Actions from '../Constants/EmailConstants'
import axios from 'axios'
import FormData from 'form-data';
import Urls from '../../Constants/urls'

export const SendContactEmailAction = (firstName?: string, lastName?: string, email?: string, phoneNumber?: string, message?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: Actions.SENDING_EMAIL_LOADING })
      await axios.post(Urls.contactEmail(), { firstName, lastName, email, phoneNumber, message, ak: true })
      dispatch({ type: Actions.SENDING_EMAIL_SUCCESS })
    } catch (error) { dispatch({ type: Actions.SENDING_EMAIL_FAILED, error }) }
  }
}

export const SendAppointmentEmailAction = (firstName?: string, lastName?: string, email?: string, phoneNumber?: string, state?: string, period?: string, monday?: boolean, tuesday?: boolean, wednesday?: boolean, thursday?: boolean, friday?: boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: Actions.SENDING_EMAIL_LOADING })
      await axios.post(Urls.setAppointmentEmail(), { firstName, lastName, email, phoneNumber, state, period, monday, tuesday, wednesday, thursday, friday, ak: true })
      dispatch({ type: Actions.SENDING_EMAIL_SUCCESS })
    } catch (error) { dispatch({ type: Actions.SENDING_EMAIL_FAILED, error }) }
  }
}


export const SendRiskProfileAction = (name?: string, email?: string, riskScore?: number | string, profileType?: string, file?: any, values?: { question: string, answer: string }[]) => {
  return async (dispatch: any) => {
    try {
      const form = new FormData();
      form.append('name', name);
      form.append('email', email);
      form.append('profileType', profileType);
      form.append('riskScore', riskScore);
      form.append('values', JSON.stringify(values));
      form.append('ak', true);
      form.append('file', file);
      dispatch({ type: Actions.SENDING_EMAIL_LOADING })
      await axios.post(Urls.submitRiskProfile(), form, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      dispatch({ type: Actions.SENDING_EMAIL_SUCCESS })
    } catch (error) { dispatch({ type: Actions.SENDING_EMAIL_FAILED, error }) }
  }
}