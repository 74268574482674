import React, { useEffect } from 'react';
import { Flex } from '../..';
import { SolutionsCard } from '../SolutionsCard';
import { Solutions } from '../../../Constants/Content';
import { HeaderHighlight } from '../..';
import SR from 'scrollreveal';
import style from './style.module.scss';

export const SolutionsSection = () => {

    useEffect(() => {
        Solutions.map(s => SR().reveal(`#${s.id}-card`, { opacity: 0.2, viewFactor: 0.3, scale: 0.9, delay: 0, reset: false, distance: '50px', origin: 'left' }))
    }, [])

    return (
        <Flex column center className={style.section}>
            <Flex column center className={style.header}>
                <h2>Find everything you need to build your extraordinary business.</h2>
                <HeaderHighlight classes={style.accent} />
            </Flex>
            <Flex className={style.card_wrapper}>
                {Solutions?.map((s: any) => { return <SolutionsCard id={`${s.id}-card`} classes={style.card} data={s} /> })}
            </Flex>
        </Flex>
    );
};
