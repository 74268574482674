import React, { useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { ContentSection } from '../../ContentSection';
import { Flex } from "../..";
import { WhiteHarbor } from '../../../Assets';
import { AppState } from "../../../Redux/Models";
import { useSelector, useDispatch } from "react-redux";
import { GetHomeSectionTwoAction } from "../../../Redux/Actions/ContentActions";


export const InvestmentPhilosophy = React.memo(() => {
  const dispatch = useDispatch()
  const data = useSelector((s: AppState) => s.content.homeLandingSection.data)

  useEffect(() => {
    dispatch(GetHomeSectionTwoAction())
  }, [dispatch])


  return (
    <Flex id="investment-philosophy" style={{ width: '100%', overflow: 'hidden' }}>
      <ContentSection
        id={'investment-philosophy-content'}
        type="wave-flipped"
        foregroundBackgroundColor="rgba(255,255,255,0.3)"
        imageBackgroundColor="rgba(255,255,255,0.3)"
        src={WhiteHarbor}
        // header={data && data?.SectionTitle}
        header={'Sound Advice'}
        content={data && <ReactMarkdown>{`${data?.SectionContent}`}</ReactMarkdown>}
      />
    </Flex>
  );
});