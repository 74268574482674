import React from 'react';
import { Flex } from '../..';
import { SectionHeader } from '../../MediaPage/SectionHeader';
import { EventCell } from '../EventCell';
import style from "./style.module.scss";
import { EventsContent } from '../../../Constants/Content';

export const EventsSection = () => {
    return (
        <Flex column className={style.wrapper}>
            <Flex>
                <SectionHeader content={<h2>Upcoming <br /> Events</h2>} />
            </Flex>
            <Flex wrap justify="space-around" className={style.events_wrapper}>
                {EventsContent?.map((e, key) => <EventCell data={e} key={key} />)}
            </Flex>
        </Flex>
    );
};
