
import React, { useEffect } from "react"
import { connect, useDispatch, useSelector } from 'react-redux'
// import { toggleNav } from '../../state/actions'
import style from './style.module.scss';
import PropTypes from 'prop-types';
import { AppState } from "../../Redux/Models";
import { ToggleMobileNavigation } from "../../Redux/Actions";

export const Hamburger = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((s: AppState) => s.utility.mobileNavOpen)
    return (
        <span className={style.container} onClick={() => dispatch(ToggleMobileNavigation(!isOpen))}>
            <div className={`${style.navIcon4} ${isOpen ? style.open : ''}`}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </span>
    );
};
