import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import logoPath from '../../Assets/Images/MainLogo.svg';
import DelayUnmount from '../DelayUnmount';
import style from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../Redux/Models';
import { Flex } from '../Flex';
import { useHistory } from 'react-router-dom';
import { ToggleContactModal, ToggleMobileNavigation } from '../../Redux/Actions';
import { ForumOutlined, NavigateNext } from '@mui/icons-material';
import { Hamburger } from '../Hamburger';
import { Button, MC2NavItem } from '..';

const Option = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let option = null;
  let icon: any = null;

  const onMouseLeave = () => gsap.to(icon, { x: 0, duration: 0.1 })
  const onMouseEnter = () => gsap.to(icon, { x: 10, duration: 0.1 })

  const onClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    dispatch(ToggleMobileNavigation(false));
    setTimeout(() => history.push(props.location), 300)
  }

  return (
    <li
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={e => option = e}
      onClick={onClick}
      className={style.option}>
      {props.label} <NavigateNext ref={e => icon = e} className={style.linkIcon} /> </li>
  )
}


const MobileDrawerComponent = DelayUnmount((props: any) => {

  const dispatch = useDispatch();
  let topNav: any = null;
  let listener: any = null;

  useEffect(() => {
    gsap.set(".top-nav-secondary", { opacity: 0, display: 'flex' })
    gsap.to("#background-overlay", { opacity: 1.0, duration: 0.3 });
    gsap.from(".top-nav-secondary", { x: window.innerWidth, opacity: 0, duration: 0.3 });
    gsap.to(".top-nav-secondary", { x: window.innerWidth - topNav.innerWidth, opacity: 1.0, delay: 0.1, duration: 0.3 });
  }, [])

  useEffect(() => {
    if (!props.status) {
      gsap.set("#background-overlay", { opacity: 1.0, display: 'flex' })
      gsap.to("#background-overlay", { opacity: 0, duration: 0.4 });
      gsap.to(".top-nav-secondary", { x: window.innerWidth, opacity: 0, display: 'none', duration: 0.4 });
    }
  }, [props.status])

  const _handleIconGrow = (id: any) => {
    gsap.to(id, { scale: 1.2, opacity: 0.8, transformOrigin: "50% 50%", duration: 0.2 })
  }

  const _handleIconShrink = (id: any) => {
    gsap.to(id, { scale: 1.0, opacity: 1.0, transformOrigin: "50% 50%", duration: 0.2 })
  }

  return (
    <Flex id="background-overlay" onClick={() => dispatch(ToggleMobileNavigation(false))} className={style.backgroundOverlay}>
      <div ref={e => topNav = e} className={`${style.topNavSecondary} top-nav-secondary`}>
        <Flex column style={{ width: '100%' }}>
          <Flex row align="center" justify="space-between" style={{ width: '100%', boxSizing: 'border-box', padding: '20px 0px', paddingLeft: 20, paddingRight: 20, paddingTop: 15 }}>
            <img
              draggable="false"
              style={{ height: 80, width: 'auto' }}
              alt="Eagle Harbor Logo"
              src={logoPath} />
            <Hamburger />
          </Flex>
          <div className={style.scrollable}>
            <div className={style.flexWrapper}>
              <ul className={style.optionsList}>
                <Option location="/" label='Home' />
                <Option location="/about" label='About' />
                <Option location="/emc2" label={<MC2NavItem/>} />
                <Option location="/services" label='Services' />
                <Option location="/business-solutions" label='Business Solutions' />
                <Option location="/pilots#hero" label='Aviation Professionals' />
                <Option location="/resources" label='Resources' />
              </ul>
              <Flex center column style={{ width: '100%', marginTop: 40 }}>
                <Button
                  IconComponent={ForumOutlined}
                  onClick={() => dispatch(ToggleContactModal(true))}
                  theme="callToActionBlack"
                  containerStyle={{ marginLeft: 20, padding: "5px 10px" }}
                >
                  Contact Us
                </Button>
                <Flex center column className={style.location_wrapper_mobile}>
                  <span>2650 N. Military Trail  <br />Suite 420 Boca Raton, FL 33431<br /> 561.576.8280</span>
                  <span>11555 Heron Bay Blvd  <br />Suite 200 Coral Springs, FL 33067 <br /> 561.576.8282</span>
                </Flex>
              </Flex>
            </div>
          </div>
        </Flex>
      </div>
    </Flex>
  )
})

export const MobileDrawer = React.memo(() => {
  const isOpen = useSelector((s: AppState) => s.utility.mobileNavOpen);
  return <MobileDrawerComponent status={isOpen} delayTime={750} />
})