import React, { useEffect } from "react";
import { Flex } from "../../Components/Flex";
import { EagleParallax } from "../../Assets";
import { ParallaxWrapper } from "../../Components/ParallaxWrapper";
import style from "./style.module.scss";
import {
  ExploreServices,
  FinancialApproach,
  LandingSection,
  InvestmentPhilosophy,
  ParallaxContent,
} from "../../Components/HomePage";
import { AnimatedVideo } from "../../Components/AnimatedVideo";
import { HeaderPageSpacer } from "../../Components/Header/HeaderPageSpacer";

export const Home = React.memo(() => {
  const videoId = "658962815";
  const VIDEO_URL = "https://www.youtube.com/watch?v=X1TDIb4-h50&t=4s";
  // https://www.youtube.com/watch?v=K5-NbjGYS6M&pp=ygUVZWFnbGUgaGFyYm9yIGFkdmlzb3Jz
  // console.log("Home screen")

  return (
    <Flex column className={style.wrapper}>
      <HeaderPageSpacer />
      <LandingSection />
      <InvestmentPhilosophy />
      {/* <FinancialApproach /> */}
      <AnimatedVideo videoUrl={VIDEO_URL} />
      <ParallaxWrapper
        src={EagleParallax}
        height="40vh"
        children={<ParallaxContent />}
      />
      <ExploreServices />
    </Flex>
  );
});
