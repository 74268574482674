import React, {
  CSSProperties,
  FC,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { ScrollDataContext, ScrollPageContext } from "./stores";
import { Animation } from "./types";
import { computeStyle } from "./utils";

interface AnimatorProps {
  children: ReactNode | ReactNode[];
  animation: Animation;
  style?: CSSProperties;
  className?: string;
}

const Animator: FC<AnimatorProps> = (props) => {
  const { children, animation, style, className } = props;
  const { currentPage, currentProgress } = useContext(ScrollDataContext);
  const { page } = useContext(ScrollPageContext);

  const calculatedStyle: CSSProperties | undefined = useMemo(() => {
    // for current (out)
    if (currentPage === page) {

      return ({
        ...computeStyle(animation?.out?.style, currentProgress),
        ...style,
      } as CSSProperties)
      // for next (in)
    } else if (currentPage === page - 1) {

      return ({
        ...computeStyle(animation?.in?.style, currentProgress),
        ...style,
      } as CSSProperties)
    }
    // lastly, out of view?
    else {
      return {display: 'none'}
      // return ({
      //   ...computeStyle(animation?.out?.style, currentProgress),
      //   ...style,
      // } as CSSProperties)
    }
  }, [currentPage, page, animation?.out?.style, animation?.in?.style, currentProgress, style]);

  return (
    <div suppressHydrationWarning style={calculatedStyle} className={className}>
      {children}
    </div>
  );
};

export default Animator;
