import React, { useCallback, useRef } from "react";
import { Flex } from "../Flex";
import { HashLink as Link } from 'react-router-hash-link';
import style from "./style.module.scss";

export type ButtonTheme =
  "orange"
  | "submit"
  | "green"
  | "neutral"
  | "delete"
  | "callToActionWhite"
  | "callToActionBlack";
export const ButtonThemes: { [key: string]: ButtonTheme } = {
  submit: "submit",
  green: "green",
  orange: "orange",
  neutral: "neutral",
  delete: "delete",
  callToActionWhite: "callToActionWhite",
  callToActionBlack: "callToActionBlack",
};

export interface ButtonProps {
  onClick?: (e: any) => void;
  children: any;
  containerStyle?: any;
  labelStyle?: any;
  disabled?: boolean;
  theme?: ButtonTheme;
  toggleDim?: boolean;
  IconComponent?: any;
  className?: any;
  link?: string;
}

export const Button = (props: ButtonProps) => {
  const {
    theme = ButtonThemes.submit,
    disabled,
    toggleDim,
    className,
    IconComponent,
    link,
  } = props;

  const isDimmable = toggleDim !== undefined ? toggleDim : true;

  const Content = useCallback(() => {
    return (
      <>
        <span
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            whiteSpace: "nowrap",
            color: "white",
            ...(props.labelStyle || {}),
          }}
        >
          {props.children}
        </span>
        {IconComponent && (
          <div
            style={{
              marginLeft: 15,
              // color: "white",
            }}
          >
            <IconComponent style={{ ...(props.labelStyle || {}), paddingTop: 5, }} />
          </div>
        )}
      </>
    )
  }, [props.labelStyle, IconComponent, props.children])

  const WrappedButton = useCallback(() => {
    if (link) return (
      <Link to={link} smooth>
        <Content />
      </Link>
    )
    return <Content />
  }, [props.labelStyle, IconComponent, props.children, props.onClick, link, disabled]);

  return (
    <Flex
      center
      onClick={(!disabled && !link && props.onClick) || undefined}
      className={`${style.button} ${className} ${disabled && isDimmable && style.disabled
        }  ${style[`${theme}Theme`]}`}
      style={props.containerStyle}
    >
      <WrappedButton />
    </Flex>
  );
};
