import React from 'react';
import { PortfolioImage } from '../../Assets';
import { Flex } from '../../Components';
import { GradientCanvas } from '../../Components/GradientCanvas';
import { LandingSection } from '../../Components/MediaPage';
import { ContentSections, PortfolioComparison } from '../../Components/PortfolioPage';
import style from './style.module.scss';
import { HeaderPageSpacer } from '../../Components/Header/HeaderPageSpacer';

export const Portfolio = (props: any) => {
  return (
    <Flex id="insights" column className={style.wrapper}>
      <HeaderPageSpacer />
      <LandingSection
        showOverflow
        hideShadow
        variant="gradient"
        image={PortfolioImage}
        headerContent={<>The most powerful force in<br />the universe is compound interest.</>} />
      <ContentSections />
      <PortfolioComparison />
    </Flex>
  )
}
