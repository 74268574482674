export const TopFiveQuestions = [
  {
    q: "Are you a fiduciary?",
    a: "Yes. A fiduciary works in the best interest of the client. Non-fiduciaries need only to recommend products that are “suitable” — without regard to cost or the client’s best interest.",
  },
  {
    q: "How do you get paid?",
    a: "We are fee-only advisors. In other words, we do not charge commissions or transaction costs. Our fees are based on a percentage of our client’s assets. While there are many firms that advertise the concept of “We do better, when you do better”, the fee-only platforms have now been around for decades, and not unique to any firm that represents this idea as a novel concept.",
  },
  {
    q: "What are my all-in costs?",
    a: "Some investments including mutual funds and ETFs have built in costs that are transparent. We perform extreme due diligence in weighing the costs of these investments versus the risk and reward they deliver. The cheapest investment may yield subpar returns. Conversely, an expensive investment may not reap the highest reward either.",
  },
  {
    q: "What are your qualifications?",
    a: `The advisors at Eagle Harbor Advisors bring decades of experience with a multitude of expertise.
        You may also use a Form ADV to check an advisor's record.`,
  },
  {
    q: "How will our relationship work?",
    a: "When you engage with Eagle Harbor Advisors, rest assured we will always be available within a reasonable amount of time to discuss any areas of concern. As a general matter, in addition to ad hock communication and timely financial communication, we will have a dynamic relationship, with a minimum of 12 monthly calls, four quarterly performance discussions, and two financial plan reviews.",
  },
  {
    q: "What's your investment philosophy?",
    a: "At Eagle Harbor Advisors, our investment philosophy is built on the foundational understanding that financial success begins first with capital preservation and the principles of effective compounding. Utilizing sound, unbiased, enhanced strategies built on a foundation of downside protection. Our proprietary models utilize both strategic and tactical allocations to minimize risk and maximize returns for each client's unique needs.",
  },
  {
    q: "What asset allocation will you use?",
    a: "While we have long term strategic asset allocations, we do not shy from making tactical changes when needed based on our research. If we feel the need, we will and have raised significant levels of cash to protect clients from extremely volatile markets.",
  },
  {
    q: "What investment benchmarks do you use?",
    a: "Every investment model or portfolio will have a blended benchmark that closely matches the style and strategy being deployed. But more importantly, the most crucial benchmark for any client is the goals that are established in a financial plan. We are more concerned with meeting the clients goals than meeting the performance of an arbitrary index.",
  },
  {
    q: "Who is your custodian?",
    a: "Eagle Harbor Advisors custodies client assets with Fidelity InstitutionalSM and clears through National Financial Services LLC (NFS), a Fidelity Investments broker-dealer. Fidelity has a reputation for integrity, with over 70 years of financial management experience and $11.2 trillion in assets under administration.  Eagle Harbor Advisors leverages the resources of Kestra Private Wealth Services, a Registered Investment Advisor and Kestra Investment Services, a leader in the new age of client-centric broker dealers.",
  },
  {
    q: "What about taxes?",
    a: "EHA ensures that our clients’ portfolios are invested in the most cost and tax efficient manner. There are always costs, and taxes can not be entirely eliminated. There is a balance between the level of taxes that may be incurred and the profit on investment. In other words, be tax conscious, but don’t chase the tax tail and give up positive returns in the process..",
  },
];
