import React from 'react'
import { Flex } from '../../Components';
import style from "./style.module.scss";
import { LandingSection } from '../../Components/MediaPage';
import { ResourcesSection } from '../../Components/MediaPage';
import { FrequentlyAskedQuestions } from '../../Components/MediaPage/FrequentlyAskedQuestions';
import { MediaImage } from '../../Assets';
import { SectionHeader } from '../../Components/MediaPage/SectionHeader';
import { SEO } from '../../Components/SEO/SEO';
import { HeaderPageSpacer } from '../../Components/Header/HeaderPageSpacer';

export const Media = () => {

    return (
        <Flex column className={style.wrapper}>
            <SEO title={"EH - Media"} description={"Discover media, articles and tools to help you succeed in your financial goals"} />
            <HeaderPageSpacer />
            <LandingSection
                hideShadow
                variant="particle"
                headerContent={<SectionHeader type="circle" content={<>An Investment Into Knowledge <br />Pays The Best Interest</>} />}
                grabber="Gain access to expert insight to help you succeed."
                image={MediaImage} />
            <ResourcesSection />
            <FrequentlyAskedQuestions />
        </Flex>
    );
};
