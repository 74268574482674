import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ExpansionList } from "../../MediaPage/FrequentlyAskedQuestions/ExpansionPanel/ExpansionList";
import style from "./style.module.scss";

interface ReadMoreProps {
    onClick?: () => void;
}

const ReadMore = (props: ReadMoreProps) => {
    const { onClick = () => { } } = props;
    return (
        <strong onClick={onClick} className={style.readMore}>
            Read More
        </strong>
    );
};


interface ExpansionPanelProps {
    children?: any;
    menuItems?: any;
    preview?: any;
    description?: any;
    onClick?: () => void;
    expanded?: boolean;
    idx?: any;
}

interface SectionHeaderProps {
    idx?: any;
    description?: any;
    preview?: any;
    toggleExpanded?: any;
    isExpanded?: boolean;
}

export const ExpansionPanel = React.memo((props: ExpansionPanelProps) => {
    const { children, idx, expanded, preview, description } = props;
    let [isExpanded, toggleExpanded] = useState(false);
    return (
        <div
            className={style.sectionWrapper}
            key={idx}
            id={idx}
        >
            <SectionHeader
                preview={preview}
                description={description}
                toggleExpanded={toggleExpanded}
                isExpanded={isExpanded}
                idx={idx}
            />
            <ExpansionList children={children} idx={idx} isMounted={expanded} />
        </div>
    );
});


const SectionHeader = (props: SectionHeaderProps) => {
    let [isHovered, toggleHover] = useState(false);

    let expandArrow: any = null;

    const { toggleExpanded, isExpanded, idx, preview, description } =
        props;

    useEffect(() => {
        if (isExpanded && expandArrow) {
            gsap.to(expandArrow, { duration: 0.2, rotation: 180 });
        } else {
            gsap.fromTo(expandArrow, { duration: 0.2, rotation: 180 }, { rotation: 0 });
        }
    }, [isExpanded]);

    return (
        <div
            onClick={() => toggleExpanded(!isExpanded)}
            className={style.sectionHeader}
            onMouseEnter={() => toggleHover(true)}
            onMouseLeave={() => toggleHover(false)}
        >
            {preview && !isExpanded && (
                <span className={style.preview}>{preview}</span>
            )}
            {preview && !isExpanded && (
                <ReadMore />
            )}
            {description && isExpanded && (
                <ExpansionList children={<p className={style.description}>
                    {description}
                    <strong style={{ marginLeft: '5px' }} className={style.readLess}><br />Read Less</strong>
                </p>} idx={idx} isMounted={isExpanded} />
            )}
        </div>
    );
};

