import React, { useEffect } from 'react';
import { Flex } from '../../../Flex';
import { Card } from '../Card';
import { HeaderHighlight } from '../../../HeaderHighlight';
import SR from 'scrollreveal';
import style from './style.module.scss';

interface CardSectionProps {
    data: any[];
    title: string;
}

export const CardSection = React.memo((props: CardSectionProps) => {
    const { data, title } = props;
    useEffect(() => {
        data?.map(d => SR().reveal(`#${d?.id}-card`, { opacity: 0.2, viewFactor: 0.3, scale: 0.9, delay: 0, reset: false, distance: '50px', origin: 'left' }))
    }, [data, title])

    return (
        <Flex column center className={style.section}>
            <Flex column center className={style.header}>
                <h2>{title}</h2>
                <HeaderHighlight classes={style.accent} />
            </Flex>
            <Flex className={style.card_wrapper}>
                {data?.map((s: any) => { return <Card id={`${s.id}-card`} classes={style.card} data={s} /> })}
            </Flex>
        </Flex>
    );
});