import React, { useState } from 'react'
import { useCallback } from 'react'
import { Flex } from '../..'
import { SectionHeader } from '../../MediaPage/SectionHeader'
import style from './style.module.scss'
import { PieChartModel } from './PieChartModel';
import { useMemo } from 'react'
import PieChart from './PieChart'

interface Exhibit {
  title: string;
  content: string;
  breakdown?: BreakDownSlice[];
}

interface BreakDownSlice {
  name: string;
  y: number;
}

export const PortfolioComparison = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const exampleExhibits: Exhibit[] = useMemo(() => [
    {
      title: 'High Volatilty',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
      breakdown: [
        { name: 'Mutual Funds', y: .1 },
        { name: 'Stocks/ADRs', y: .5 },
        { name: 'Futures', y: .4 },
      ]
    },
    {
      title: 'Long Term Hold',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
      breakdown: [
        { name: 'Mutual Funds', y: .4 },
        { name: 'Stocks/ADRs', y: .2 },
        { name: 'Precious Metals', y: .4 },
      ]
    },
    {
      title: 'Covered Calls',
      content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
      breakdown: [
        { name: 'Options', y: .3 },
        { name: 'Stocks/ADRs', y: .6 },
        { name: 'Precious Metals', y: .1 },
      ]
    }
  ], [])


  const selectedExhibitChartData: PieChartModel = useMemo(() => ({
    chartSeriesList: [
      {
        name: `${exampleExhibits[selectedIndex].title} Breakdown`,
        type: 'pie',
        data: exampleExhibits[selectedIndex].breakdown
      }
    ],
    title: `${exampleExhibits[selectedIndex].title} Breakdown`,
    valuePrefix: '$',
    valueSuffix: '%'
  }), [exampleExhibits, selectedIndex]);

  return (
    <Flex column style={{ width: '100%' }} className={style.wrapper}>
      <SectionHeader content={<h2 style={{ margin: 0 }}>Portfolio<br /><span style={{ paddingLeft: 10 }}>Comparison</span></h2>} />
      <Flex style={{ width: '100%', marginTop: 30 }}>
        <Flex column className={style.exhibitListWrapper}>
          {exampleExhibits.map((e, idx) => <ExhibitInfo key={idx} onClick={() => setSelectedIndex(idx)} data={e} selected={idx === selectedIndex} />)}
        </Flex>
        <Flex className={style.pieChartFlexWrapper} center>
          <Flex center className={style.pieChartWrapper}>
            <PieChart
              targetId="portfolio-exhibit"
              data={selectedExhibitChartData}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const ExhibitInfo = ({ onClick, selected, data }: { onClick?: () => any, selected?: boolean, data: Exhibit }) => {
  return (
    <Flex style={{ width: '100%' }} className={`${style.exhibitInfoWrapper} ${selected ? style.exhibitInfoWrapperActive : ''}`} column onClick={onClick}>
      <h3 className={style.exhibitTitle}>{data.title}</h3>
      <p className={style.exhibitContent}>{data.content}</p>
    </Flex>
  )
};
