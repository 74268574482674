import React from 'react';
import { BannerContent } from '../Shared/Banner/BannerContent';

export const Banner = () => {
    return (
        <BannerContent
            title="Financial Planning for Professional Pilots"
            showBrand={false}
            content="Eagle Harbor Advisors is uniquely equipped to assist Aviation Professionals plan, execute and monitor their financial flight plan. We view our role in this relationship as the invaluable copilot, completely focused on assisting you reach your financial destinations safely and on time."
        />
    );
};

