import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { Flex } from '../..'
import { ContentSection } from '../../ContentSection';
import style from './style.module.scss'
import { AppState, IBusinessServiceSection, IIndividualServiceSection } from '../../../Redux/Models';
import ReactMarkdown from 'react-markdown';
import { BulletItem } from '../../BulletItem/BulletItem';

const IndividualServiceOptions = {
  firstRow: {
    type: "wave-flipped",
    fill: "#0C3F75",
  },
  middleRow: {
    type: "white",
  },
  lastRow: {
    type: "wave",
    fill: "#0C3F75",
    gradientFill: { topColor: "#201E50", bottomColor: "#2A93D1" },
  }
}

const BusinessServiceOptions = {
  firstRow: {
    type: "wave-flipped",
    fill: "#201E50",
    gradientFill: { topColor: "#201E50", bottomColor: "#201E50" },
  },
  middleRow: {
    type: "white",
    dropShadowColor: 'rgba(55, 167, 219, 0.5)',
  },
  lastRow: {
    type: "wave",
    fill: "#201E50",

    // // HideBackground
    // dropShadowColor: 'rgba(55, 167, 219, 0)',
    // imageBackgroundColor: 'transparent',
    // foregroundBackgroundColor: 'transparent',
    // extraContentBackground: 'rgb(32,30,80)',

    // // AllowOverflow
    // allowOverflow: true,

    color: "white",
  }
}

const HideBackgroundOptions = {
  dropShadowColor: 'rgba(55, 167, 219, 0)',
  imageBackgroundColor: 'transparent',
  foregroundBackgroundColor: 'transparent',
  extraContentBackground: 'rgb(32,30,80)',
}

const AllowOverflowOptions = {
  allowOverflow: true,
}

export const ServicesSection = (props: any) => {

  const { loading: bLoading, data: bData, error: bError } = useSelector((s: AppState) => s.content.businessServices)
  const { loading: iLoading, data: iData, error: iError } = useSelector((s: AppState) => s.content.individualServices)

  const individualServices = useMemo(() => {
    const GenerateIndividualService = (s: IIndividualServiceSection) => {
      return {
        id: s.ContentId,
        content: s.Service?.ContentText,
        header: s.Service?.Title,
        src: s.Service?.ContentImage?.url,
        ...(s.Service?.HideImageBackground ? HideBackgroundOptions : {}),
        ...(s.Service?.AllowOverflow ? AllowOverflowOptions : {})
      }
    }

    return iData?.sort((a: any, b: any) => a?.id - b?.id)?.map((d, key) => {
      if (key === 0) return {
        ...IndividualServiceOptions.firstRow,
        ...GenerateIndividualService(d),
        reverse: !!(key % 2),
      }
      else if (key === iData.length - 1) return {
        ...IndividualServiceOptions.lastRow,
        ...GenerateIndividualService(d),
        reverse: !!(key % 2),
      }
      else return {
        ...IndividualServiceOptions.middleRow,
        ...GenerateIndividualService(d),
        reverse: !!(key % 2)
      }
    }) || []
  }, [iData])

  const businessServices = useMemo(() => {
    const GenerateBusinessService = (key: number, s: IBusinessServiceSection, extraOptions?: { [key: string]: any }) => {
      return {
        reverse: !(key % 2),
        id: s.ContentId,
        content: s.Service?.ContentText,
        header: s.Service?.Title,
        src: s.Service?.ContentImage?.url,
        extraContent: s.LearnMoreBusiness ? () => (
          <Flex column className={`${style.markdownListWrapper} ${extraOptions?.color === 'white' ? style.whiteContent : null}`}>
            {s.LearnMoreBusiness?.FirstParagraphList && s.LearnMoreBusiness?.FirstParagraphList?.map((item, key) => <ReactMarkdown children={`${item.Paragraph}`} />)}
            {s.LearnMoreBusiness?.FirstChecklist && <Flex column align="flex-start" style={{ width: '100%' }}>
              {s.LearnMoreBusiness?.FirstChecklist.map((item, key) => <BulletItem key={key} content={item.ListItem} style={extraOptions?.color ? { color: extraOptions?.color } : null} />)}
            </Flex>}
            <div style={{ marginTop: 20 }}>
              {s.LearnMoreBusiness?.SecondParagraphList?.map((item, key) => <ReactMarkdown children={`${item.Paragraph}`} />)}
              {s.LearnMoreBusiness?.SecondChecklist &&
                <Flex column align="flex-start" style={{ width: '100%', margin: '10px 0px' }}>
                  {s.LearnMoreBusiness?.SecondChecklist.map((item, key) => <BulletItem key={key} content={item.ListItem} style={extraOptions?.color ? { color: extraOptions?.color } : null} />)}
                </Flex>}
            </div>
          </Flex>
        ) : undefined,
        ...(s.Service?.HideImageBackground ? HideBackgroundOptions : {}),
        ...(s.Service?.AllowOverflow ? AllowOverflowOptions : {}),
        ...(extraOptions || {})
      }
    }
    return bData?.sort((a: any, b: any) => a?.id - b?.id).map((d, key) => {
      if (key === 0) return GenerateBusinessService(key, d, BusinessServiceOptions.firstRow)
      else if (key === bData.length - 1) return GenerateBusinessService(key, d, BusinessServiceOptions.lastRow)
      else return GenerateBusinessService(key, d, BusinessServiceOptions.middleRow)
    }) || []
  }, [bData])

  if (!businessServices.length && !individualServices.length) return null;

  return (
    <Flex className={style.wrapper} column align="center">
      <Flex id="indiv-services-header" justify="center" align="flex-end" style={{ height: '150px', position: 'relative', width: '100%', background: "#0C3F74" }}>
        <h1 style={{ margin: 0, color: 'white', padding: '10px 20px', borderRadius: 20 }}>Services for Individuals</h1>
      </Flex>
      {individualServices.map((s, key) => <ContentSection {...s} key={key} />)}
      <Flex id="biz-services-header" justify="center" align="flex-end" style={{ height: '250px', position: 'relative', width: '100%', background: "#201E50" }}>
        <h1 style={{ margin: 0, color: 'white', padding: '10px 20px', borderRadius: 20 }}>Business Services</h1>
      </Flex>
      {businessServices.map((s, key) => <ContentSection {...s} key={key} />)}
    </Flex>
  )
}

