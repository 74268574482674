import React from 'react';
import { TickerTape } from "react-ts-tradingview-widgets";
const tickerSymbols = [
    {
        "title": "DOW 30",
        "proName": "US30"
    },
    {
        "title": "S&P 500",
        "proName": "SPXUSD"
    },
    {
        "title": "NASDAQ",
        "proName": "SKILLING:NASDAQ"
    },
    {
        "title": "S&P Futures",
        "proName": "AMEX:SPY"
    },
    {
        "title": "Gold",
        "proName": "TVC:GOLD"
    },
    {
        "title": "US Crude",
        "proName": "EASYMARKETS:OILUSD"
    },
    {
        "title": "GBP/USD",
        "proName": "FX:GBPUSD"
    },
    {
        "title": "EUR/USD",
        "proName": "OANDA:EURUSD"
    }
]


export const TradingViewMarque = React.memo(() => {
    return (
        <div style={{ width: '100%' }}>
            <TickerTape displayMode="regular" colorTheme="light" symbols={tickerSymbols} copyrightStyles={{
                parent: {
                    fontSize: "24px",
                    color: "transparent",
                    height: 0,
                },
                link: {
                    textDecoration: "line-trough",
                },
                span: {
                    color: "darkblue",
                },
            }}></TickerTape>
        </div>
    );
});