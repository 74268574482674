import React, { Component } from 'react';
import { SectionHeader } from '../';
import style from './style.module.css';
import { TweenMax } from 'gsap';

export default class Quotes extends Component {

    quoteInterval = null;

    state = {
        index: 0,
        quotes: [
            {
                author: 'Sean Pillar',
                quote: 'Thank you for the exceptional experience filming in Guam, We got every beautiful angle. Your team is fearless!'
            },
            {
                author: 'Perry Chencin',
                quote: `The team was so easy to work with. Never would have thought creating a segment for our business would be so fun!`
            },
            {
                author: 'Glen Turner',
                quote: 'The reaction we have received from our segment has been incredible! Since it`s aired, our school has the first waiting list for enrollment since we started over 20 years ago!'
            },
            {
                author: 'Roman Chvalbo',
                quote: 'Really appreciated your teams culture. The task of the modern educator is not to cut down jungles. But to irrigate deserts.'
            }
        ]
    }

    setQuoteInterval = () => {
        this.quoteInterval = setInterval(() => {
            TweenMax.to(this.quoteRef, 0.3, { opacity: 0, x: 100 })
            this.setState({
                index: this.state.index < this.state.quotes.length - 1 ? this.state.index + 1 : 0
            }, () => {
                TweenMax.fromTo(this.quoteRef, 0.3, { opacity: 0, x: -100 }, { opacity: 1, x: 0 })
            })
        }, 6000);
    }

    onSelectOption = (id) => {
        TweenMax.to(this.quoteRef, 0.3, { opacity: 0, x: 100 })
        this.setState({
            index: id
        }, () => {
            TweenMax.fromTo(this.quoteRef, 0.3, { opacity: 0, x: -100 }, { opacity: 1, x: 0 })
        })
    }

    componentDidMount() {
        this.setQuoteInterval();
    }

    render() {
        return (
            <div className={style.testGlobalWrapper}>
                <div className={style.header}>
                </div>
                <div className={style.headerRight}>
                </div>
                <SectionHeader header="TESTIMONIALS" />
                <div className={style.contentWrapper}>
                    <div ref={e => this.quoteRef = e} className={style.quote}>
                        <h1 className={style.largeQuote}>"</h1>
                        <span className={style.quoteText}>{this.state.quotes[this.state.index].quote}</span>
                        <div className={style.authorWrapper}>
                            <div className={style.customDash}></div>
                            <span className={style.author}>{this.state.quotes[this.state.index].author}</span>
                        </div>
                    </div>
                    <div className={style.dots}>
                        {
                            this.state.quotes.map((item, key) => {
                                return (
                                    <div key={key} onClick={() => this.onSelectOption(key)} className={`${style.singleDot} ${this.state.index === key ? style.white : style.grey}`}></div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}