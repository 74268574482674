import React from 'react';
import { Flex } from '../..';
import { PresenterCard } from '../PresenterCard';
import { MoreButton } from '../../MoreButton';
import { TeamBio } from '../../../Constants/Content';
import style from './style.module.scss';

interface WebinarCellProps {
    classes?: any;
    data?: any;
}

export const WebinarCell = (props: WebinarCellProps) => {
    const { classes, data } = props;
    return (
        <Flex column className={`${style.wrapper} ${classes}`}>
            <Flex column justify="flex-start" className={style.banner}>
                <h3 className={style.webinar_topic}>{data?.topic}</h3>
                <span className={style.date_wrapper}>{data?.date}</span>
                <span className={style.event_time}>{data?.time}</span>
            </Flex>
            <Flex column className={style.featured_section}>
                <h4>Featured Presenters</h4>
                <Flex wrap justify="flex-start" className={style.presenter_wrapper}>
                    {TeamBio.slice(0, 3).map((b) => <PresenterCard data={b} />)}
                </Flex>
                <MoreButton iconClass={style.buttonIcon} buttonClass={style.registerButton}>Register</MoreButton>
            </Flex>
        </Flex>
    );
};
