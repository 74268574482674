import React from 'react';
import { Lighthouse } from '../../../Assets';
import { Flex } from '../..';
import style from './style.module.scss';

export const LandingMobileBg = () => {
    return (
        <Flex className={style.landing_mobile_wrapper}>
            <img src={Lighthouse} draggable="false" alt="eha landing" />
        </Flex>
    );
};
