import React from 'react';
import { Flex } from '../../Components';
import style from "./style.module.scss";
import { LandingSection } from '../../Components/EventsPage';
import { EventsSection } from '../../Components/EventsPage/EventsSection';
import { WebinarSection } from '../../Components/EventsPage/WebinarSection';
import { SEO } from '../../Components/SEO/SEO';
import { HeaderPageSpacer } from '../../Components/Header/HeaderPageSpacer';

const Grabber = () => {
    return (
        <span className={style.events_grabber}>Join us for future events.</span>
    )
}


export const Events = () => {
    return (
        <Flex id="upcoming" column className={style.wrapper}>
            <HeaderPageSpacer />
            <SEO title={"EH - Events"} description={"View our upcoming events as well as past events"} />
            <LandingSection
                hideShadow
                headerContent={<>Events &<br />Webinars</>}
                grabber={<Grabber />}
            />
            <EventsSection />
            <WebinarSection />
        </Flex>
    );
};
