import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { GetAboutTeamAction } from '../../../Redux/Actions/ContentActions';
// import SR from 'scrollreveal';
import { AppState } from '../../../Redux/Models';
import { Flex } from "../..";
import style from "./style.module.scss";
import { HeaderHighlight } from '../..';
import { ItemCard } from './ItemCard';
//@ts-ignore
import { Fade } from 'react-reveal';

export const TeamBioSection = () => {
    const dispatch = useDispatch()
    const data = useSelector((s: AppState) => s?.content?.aboutTeam?.data)

    // useEffect(() => {
    //     SR().reveal('.meet_the_team', { opacity: 0.2, viewFactor: 0.3, scale: 0.9, delay: 0, reset: false, distance: '50px', origin: 'top' });
    // }, [])

    useEffect(() => {
        dispatch(GetAboutTeamAction())
    }, [dispatch])

    return (
        <Flex id="team-bio" column center className={style.wrapper}>
            <Fade top distance="50px" duration={500}>
                <Flex className="meet_the_team" column justify="flex-start">
                    <HeaderHighlight />
                    <h2 style={{ padding: 0, margin: '0 0 40px 0' }}>Meet the Team</h2>
                </Flex>
            </Fade>
            {data && <Flex wrap center>
                {data?.TeamMembers?.map((d: any) => {
                    return <ItemCard data={d} />
                }
                )}
            </Flex>}
        </Flex >
    );
};
