import React, { useMemo } from "react"
import { ScrollContainer } from "../../Components/ReactScrollMotionMaster/src";
import { LandingSection } from "./LandingSection";
import { EMC2Section } from "./EMC2Section";
import { useAppHeaderThemeSetter } from "../../Components/Header/useAppHeaderTheme";
import { useSelector } from "react-redux";
import { AppState } from "../../Redux/Models";
import { WindowSizes } from "../../Redux/Models/Utility/WIndowSizes";

const windowSizeSelector = (s: AppState) => s.utility.appWindowSize

export const Emc2 = React.memo(() => {
    const appSize = useSelector(windowSizeSelector)

    const theme = useMemo(() => {
        switch (appSize) {
          case WindowSizes.regular: return 'transparent-start'
          case WindowSizes.smallTablet: return 'primary'
          case WindowSizes.mobile: return 'primary'
          case WindowSizes.smallMobile: return 'primary'
          case WindowSizes.tinyMobile: return 'primary'
          default: return 'transparent-start'
        }
      }, [appSize])

    useAppHeaderThemeSetter(theme)
    
    return (
        <ScrollContainer>
            <LandingSection />
            <div style={{ height: '5vh', width: '100vw', background: 'transparent' }} />
            <EMC2Section />
        </ScrollContainer>
    )
})