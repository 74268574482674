import React, { useEffect } from 'react';
import style from './style.module.scss';
import { Parallax } from 'react-scroll-parallax';
import { Flex } from '../Flex';

interface ParallaxWrapperProps {
    src?: any;
    height?: string;
    children?: any;
    classes?: any;
    opacity?: number;
}


export const ParallaxWrapper = React.memo((props: ParallaxWrapperProps) => {

    return (
        <Flex row style={{ height: `${props.height}` && `${props.height}` }} className={`${style.wrapper} ${props.classes}`}>
            <Flex className={style.parallax_section}>
                <div className={style.overlay}></div>
                <Parallax className={style.parallax} y={[-20, 20]} tagInner="figure">
                    <img draggable={false} style={{ width: '100%' }} src={props.src} alt="parallax" />
                </Parallax>
            </Flex>
            <Flex center className={style.content}>{props.children}</Flex>
        </Flex>

    );
});
