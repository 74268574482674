import React from 'react';
import { Flex } from '..';
import style from './style.module.scss';
import { ReusableStaticImage } from '..';

interface BannerImageProps {
    data?: any;
}

const BannerImage = (props: BannerImageProps) => {
    const { data } = props;
    return (
        <Flex className={style.image} id={data?.id} center column>
            <ReusableStaticImage width={data?.width} image={data?.image} />
            <span style={{ marginTop: '20px', fontWeight: 500 }}>{data?.label}</span>
        </Flex>
    )
}

interface BannerImageSectionProps {
    data?: any;
}

export const BannerImageSection = (props: BannerImageSectionProps) => {
    return (
        <Flex className={style.image_section} justify="space-evenly" wrap style={{ width: '100%' }}>
            {props.data?.map((i: any) => { return <BannerImage data={i} /> })}
        </Flex>
    );
};
