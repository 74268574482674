import React from 'react';
import { Flex } from "../../Flex";
import { AnimatedModal } from '../AnimatedModal';

export interface MediaModalProps {
    children: any;
    isOpen: boolean;
    toggleModal: (nextState: boolean) => void;
    key?: any;
}

export const MediaModal = React.memo((props: MediaModalProps) => {
    const { children, isOpen, toggleModal, key } = props;

    return (
        <AnimatedModal
            open={isOpen}
            key={key}
            onClose={toggleModal}>
            {children}
        </AnimatedModal >
    );
});
