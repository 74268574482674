import React, { useMemo } from 'react';
import { Flex } from '..';
import style from './style.module.scss';
import { AppState } from '../../Redux/Models';
import { useSelector } from 'react-redux';
import { WindowSizes } from '../../Redux/Models/Utility/WIndowSizes';
//@ts-ignore
import { Fade } from 'react-reveal';


export interface DoubleBlockContentProps {
  leanLeft?: boolean;
  src?: any;
  background?: any;
  allowOverflow?: boolean;
  imageBackgroundColor?: string;
  foregroundBackgroundColor?: string;
  dropShadowColor?: string;
  type?: 'native' | 'double';
}

const DoubleBlock = (props: DoubleBlockContentProps) => {

  return (
    <Flex className={`block2 ${style.commonBlock}`} center style={{ background: props.imageBackgroundColor || 'rgba(57,168,217, 0.1)' }}>
      <Fade distance={'50%'} duration={500} left={!props.leanLeft} right={props.leanLeft}>
        <Flex className={`block-${props.leanLeft ? 'left' : 'right'} ${style.commonBlock}`} center style={{ boxShadow: `${props.leanLeft ? 10 : -10}px 15px 100px -10px ${props.dropShadowColor || 'rgba(0,0,0,0.4)'}`, background: props.foregroundBackgroundColor ? props.foregroundBackgroundColor : 'rgb(110,110,110)', position: 'relative', top: -20, left: !!props.leanLeft ? -20 : 20, overflow: props.allowOverflow ? 'visible' : 'hidden', }}>
          {!!props.src && <img style={props.allowOverflow ? { width: '100%', borderRadius: 10, overflow: 'hidden' } : { height: '100%', borderRadius: 10 }} alt="block content" src={props.src} />}
        </Flex>
      </Fade>
    </Flex>
  )
}

const Native = (props: DoubleBlockContentProps) => {

  return (
    <Flex className={`block ${style.commonBlock}`} center>
      <Fade distance={'50%'} duration={500} left={!props.leanLeft} right={props.leanLeft}>
        <Flex className={`block-${props.leanLeft ? 'left' : 'right'} ${style.commonBlock}`} center style={{
          boxShadow: `${props.leanLeft ? 10 : -10}px 15px 100px -10px ${props.dropShadowColor || 'rgba(0,0,0,0.4)'}`, background: `${props.background}` ? `${props.background}` : 'transparent', position: 'relative', overflow: 'hidden', borderRadius: 10
        }}>
          {!!props.src && <img draggable={false} style={props.allowOverflow ? { width: '100%', borderRadius: 10, overflow: 'hidden' } : { height: '100%', borderRadius: 10 }} alt="native content" src={props.src} />
          }
        </Flex>
      </Fade>
    </Flex >
  )
}

export const DoubleBlockContent = ({ leanLeft = false, ...restProps }: DoubleBlockContentProps) => {

  const appSize = useSelector((s: AppState) => s.utility.appWindowSize);

  const defaultToNative = useMemo(() => {
    switch (appSize) {
      case WindowSizes.tinyMobile:
      case WindowSizes.smallMobile: return true
    }
  }, [appSize]);

  if (defaultToNative) restProps.type = "native";

  return (
    <>
      <ImageType leanLeft={leanLeft} {...restProps} />
    </>
  )
}

const ImageType = (props: DoubleBlockContentProps) => {
  switch (props.type) {
    case 'native': return <Native {...props} />
    case 'double': return <DoubleBlock {...props} />
    default:
      return <DoubleBlock {...props} />
  }
}
