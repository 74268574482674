import React from 'react'
import style from './style.module.scss';

export const Wave = (props: { id?: string, height?: any, width?: any, fill?: string, gradientFill?: { topColor: string, bottomColor: string } }) => {

  if (props.gradientFill) {
    return (
      <div className={style.waveWrapper} style={{ position: 'relative', overflow: 'hidden' }}>
        <svg width={props.width || "70vw"} viewBox="0 0 2000 646">
          <defs>
            <path d="M2000,197.347 C1990.72,207.899 1981.2,218.15 1971.59,228.107 C1909.3,292.655 1837.47,352.001 1753.52,368.432 C1614.5,395.641 1476.29,300.315 1335.65,313.768 C1166.04,329.993 1034.97,498.372 866.767,527.447 C738.148,549.679 610.828,486.98 481.191,473.907 C365.693,462.259 247.129,491.372 146.858,556.001 C101.235,585.407 58.911,622.146 9.472,642.602 C6.37550075,643.882933 3.2355505,645.085852 0.06,646.208794 L0.06,0 L2000,0" id="path-1"></path>
            <linearGradient id={`wave-gradient-${props.id}`} x1="0" x2="0" y1="0" y2="1">
              <stop offset="30%" stop-color={props.gradientFill.topColor || 'transparent'} />
              <stop offset="100%" stop-color={props.gradientFill.bottomColor || 'transparent'} />
            </linearGradient>
          </defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="wave-background" transform="translate(-3827.000000, -971.000000)">
              <g id="Group-539" transform="translate(4827.000000, 1293.500000) scale(-1, -1) translate(-4827.000000, -1293.500000) translate(3827.000000, 970.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Clip-443"></g>
                <rect id="Rectangle" fill={props.gradientFill ? `url(#wave-gradient-${props.id})` : props.fill ? props.fill : '#2A93D1'} mask="url(#mask-2)" x="0" y="0" width="2000" height="646"></rect>
              </g>
            </g>
          </g>
        </svg>
      </div>
    )
  }


  return (
    <div className={style.waveWrapper} style={{ position: 'relative', overflow: 'hidden' }}>
      <svg width={props.width || "70vw"} viewBox="0 0 2000 646">
        <defs>
          <path d="M2000,197.347 C1990.72,207.899 1981.2,218.15 1971.59,228.107 C1909.3,292.655 1837.47,352.001 1753.52,368.432 C1614.5,395.641 1476.29,300.315 1335.65,313.768 C1166.04,329.993 1034.97,498.372 866.767,527.447 C738.148,549.679 610.828,486.98 481.191,473.907 C365.693,462.259 247.129,491.372 146.858,556.001 C101.235,585.407 58.911,622.146 9.472,642.602 C6.37550075,643.882933 3.2355505,645.085852 0.06,646.208794 L0.06,0 L2000,0" id="path-1"></path>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="wave-background" transform="translate(-3827.000000, -971.000000)">
            <g id="Group-539" transform="translate(4827.000000, 1293.500000) scale(-1, -1) translate(-4827.000000, -1293.500000) translate(3827.000000, 970.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-443"></g>
              <rect id="Rectangle" fill={props.fill ? props.fill : '#2A93D1'} mask="url(#mask-2)" x="0" y="0" width="2000" height="646"></rect>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}
