import React from 'react'

export const ShieldIcon = () => {
  return (
    <svg width="316px" height="373px" viewBox="0 0 516 573" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>trust</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <filter x="-15.0%" y="-13.2%" width="130.0%" height="126.4%" filterUnits="objectBoundingBox" id="filter-1">
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="16.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.258823529   0 0 0 0 0.866666667   0 0 0 0 0.756862745  0 0 0 1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="vecteezy_geometric-wave-background_dp0121" transform="translate(-1811.000000, 28.000000)" fill-rule="nonzero">
          <g id="trust" filter="url(#filter-1)" transform="translate(1844.000000, 0.000000)">
            <path d="M435,61 C335.244,61 233.262,4.541 232.236,3.984 L225,0 L217.764,3.984 C216.738,4.541 114.756,61 15,61 L0,61 L0,76 C0,443.119 211.89,508.294 220.913,510.843 L225,512 L229.087,510.843 C238.11,508.294 450,443.119 450,76 L450,61 L435,61 Z" id="Path" fill="#F9F5F3"></path>
            <path d="M450,76 L450,61 L435,61 C335.244,61 233.262,4.541 232.236,3.984 L225,0 L225,512 L229.087,510.843 C238.11,508.294 450,443.119 450,76 Z" id="Path" fill="#F0DBD8"></path>
            <path d="M225,34.072 C200.361,46.611 117.583,86.444 30.117,90.634 C35.098,397.812 196.143,470.296 225,480.623 C253.857,470.296 414.902,397.811 419.883,90.634 C332.417,86.444 249.639,46.611 225,34.072 Z" id="Path" fill="#38A8DA"></path>
            <path d="M419.883,90.634 C332.417,86.444 249.639,46.611 225,34.072 L225,480.623 C253.857,470.296 414.902,397.812 419.883,90.634 Z" id="Path" fill="#208EBF"></path>
            <path d="M225,91 C150.601,91 90,151.601 90,226 C90,300.399 150.601,361 225,361 C299.399,361 360,300.399 360,226 C360,151.601 299.399,91 225,91 Z" id="Path" fill="#F9F5F3"></path>
            <path d="M360,226 C360,300.399 299.399,361 225,361 L225,91 C299.399,91 360,151.601 360,226 Z" id="Path" fill="#F0DBD8"></path>
            <polygon id="Path" fill="#4AF1D3" points="274.395 185.395 225 234.789 210 249.789 175.605 215.395 154.395 236.605 210 292.211 225 277.211 295.605 206.605"></polygon>
            <polygon id="Path" fill="#42DDC1" points="295.605 206.605 274.395 185.395 225 234.789 225 277.211"></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}
