import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactMarkdown from 'react-markdown'
import { GetAboutSectionOneAction } from '../../../Redux/Actions/ContentActions';
import { Flex } from "../..";
import style from "./style.module.scss";
import SR from 'scrollreveal';
import { HeaderHighlight } from '../..';
import { ShieldIcon } from '../../ShieldIcon';
import { BulletItem } from '../../BulletItem/BulletItem';

export const AboutSection = () => {
    const dispatch = useDispatch()
    const data = useSelector((c: any) => c.content.aboutSectionOne.data)

    useEffect(() => {
        SR().reveal('.principles_section', { opacity: 0.2, viewFactor: 0.3, scale: 0.9, delay: 0, reset: false, distance: '50px', origin: 'bottom' });
        SR().reveal('.shield', { opacity: 0.2, viewFactor: 0.3, scale: 0.9, delay: 0, reset: false, distance: '50px', origin: 'top' });
    }, [])

    useEffect(() => {
        dispatch(GetAboutSectionOneAction())
    }, [dispatch])

    return (
        <Flex id="our-principles" align="center" className={style.wrapper} style={{ overflow: "hidden" }}>
            <Flex
                column
                center
                justify="flex-start"
                align="flex-start"
                className={`shield ${style.image_section}`}>
                <Flex className={style.imageWrapper}>
                    <ShieldIcon />
                </Flex>
            </Flex>
            <Flex column className={`principles_section ${style.vision_content_wrapper}`}>
                {data && <>
                    <Flex className={style.header} column justify="flex-start">
                        <HeaderHighlight background="#fff" />
                        <h2 style={{ padding: 0, margin: '0 0 20px 0', fontSize: '32px' }}>{data?.ContentTitle}
                        </h2>
                        {data?.Checklist.map((c: any) => <BulletItem content={c.ListItem} />)}
                    </Flex>
                    <p className={style.content_section}>
                        <ReactMarkdown children={`${data?.ContentSection}`} />
                    </p></>}
            </Flex>
        </Flex>
    );
};
