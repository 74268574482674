import React from 'react';
import { PilotStepProcess } from '../../../Constants/Content';
import { ContentSection } from '../../ContentSection';

export const SixSteps = React.memo(() => {
    return (
        <div style={{ width: '100%' }}>
            {PilotStepProcess.map((s, key) => <ContentSection {...s} id={`${s.id}`} fill={s.fill} type={s.type} src={s.src} key={`${s.id}-${key}`} header={s.header} content={s.content} reverse={s.reverse} />)}
        </div>
    );
});
