import React from 'react';
import { Flex } from "..";
import style from "./style.module.scss";
import { LeftSection } from "./LeftSection";

export interface HeroProps {
    header?: any;
    grabber?: any;
    buttonTheme?: "callToActionBlack" | "callToActionWhite";
    image?: any;
    onClick?: (e?: any) => any;
    color?: string;
    height?: string;
    top?: any;
    hideShadow?: boolean;
    showOverflow?: boolean;
    className?: any;
    children?: any;
    hideAppointmentBtn?: boolean;
}

export const Hero = (props: HeroProps) => {
    const { header, grabber, image, buttonTheme, onClick = () => { }, color, height, hideShadow, showOverflow, className, children, hideAppointmentBtn } = props;
    return (
        <Flex row className={style.wrapper} align="center">
            <LeftSection
                hideAppointmentBtn={hideAppointmentBtn}
                color={color}
                header={header}
                grabber={grabber}
                onClick={() => onClick()}
                buttonTheme={buttonTheme}
            />
            <div className={`${style.image_wrapper} ${className}`} style={{ overflow: showOverflow ? 'visible' : 'hidden', borderRadius: 20, boxShadow: hideShadow ? '' : '0px 0px 30px -5px rgba(0,0,0,0.5)', padding: 0, height: `${height}` ? `${height}` : '90%' }}>
                {children && children}
                {image && < img className={style.hero_img} style={{ height: `${height}` ? `${height}` : '110%', position: 'relative', top: -5 }} draggable="false" alt="hero" src={image} />}
            </div>
        </Flex>
    );
};
