import { combineReducers, Reducer } from "redux";
import { AppState } from "../Models";
import { UtilityReducer } from "./UtilityReducer";
import { EmailReducer } from './EmailReducer';
import { ContentReducer } from './ContentReducer';

const AppReducer: Reducer = combineReducers({
    utility: UtilityReducer,
    email: EmailReducer,
    content: ContentReducer
});

const App = (state: AppState | undefined, action: any) => {
    return AppReducer(state, action);
};

export default App;
