import React, { useState } from 'react';
import { Flex } from "../..";
import style from './style.module.scss';
import { ExpansionGroup } from '.';
import { TopFiveQuestions } from '../../../Constants/FAQ';
import { SectionHeader } from '../SectionHeader';
import { FaqBox } from './FaqBox';


const GrabberContent = (props: any) => {
    return (
        <span
            className={style.grabber_content}>
            {props.content}
        </span>
    )
}

export const FrequentlyAskedQuestions = () => {

    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    return (
        <Flex column className={style.wrapper}>
            <SectionHeader content={<h2>Frequently<br />Asked Questions</h2>} />
            <Flex center column style={{ width: '100%' }}>
                <h1>
                    We’ve Got Your Questions Covered
                </h1>
                <GrabberContent
                    content="Review the resources below for answers to commonly asked questions."
                />
            </Flex>
            <Flex justify="center" align="flex-start" className={style.contentWrapper}>
                <FaqBox />
                <Flex column className={style.expansionWrapper}>
                    {TopFiveQuestions.map((q, key) => <ExpansionGroup onClick={() => setExpandedIndex(key === expandedIndex ? null : key)} expanded={expandedIndex === key} idx={key} header={q.q}>
                        <p>{q.a}</p>
                    </ExpansionGroup>)}
                </Flex>
            </Flex>
        </Flex>
    );
};
