import React from 'react';
import { BannerWithParrallax } from '../Shared/Banner/BannerWithParrallax';
import { Plane } from '../../../Assets';
import { Banner } from './Banner';

export const Hero = () => (
    <div id="hero" style={{ width: '100%' }}>
        <BannerWithParrallax
            image={Plane}
            children={<Banner />}
        />
    </div>
);
