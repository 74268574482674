import React, { useMemo } from "react";
import { Flex } from "..";
import style from "./style.module.scss";
import { Wave } from "../Wave";
import { useSelector } from "react-redux";
import { AppState } from "../../Redux/Models";
import { WindowSizes } from "../../Redux/Models/Utility/WIndowSizes";

interface WaveWrapperProps {
  children: any;
  fill?: string;
  gradientFill?: { topColor: string; bottomColor: string };
  flipped?: boolean;
  reverse?: boolean;
  id?: string;
}

export const WaveWrapper = (props: WaveWrapperProps) => {
  const appSize = useSelector((s: AppState) => s.utility.appWindowSize);

  const showWave = useMemo(() => {
    switch (appSize) {
      case WindowSizes.extraLarge:
        return false;
      case WindowSizes.regular:
      case WindowSizes.large:
      case WindowSizes.small:
        return true;
      default:
        return false;
    }
  }, [appSize]);

  return (
    <Flex row className={style.wrapper}>
      <Flex
        className={style.waveContainer}
        column
        style={{
          position: "relative",
          background: showWave
            ? "transparent"
            : props.gradientFill
            ? `linear-gradient(${props.gradientFill.bottomColor}, ${props.gradientFill.topColor})`
            : props.fill,
        }}
      >
        {showWave && (
          <Flex row className={style.background}>
            {!props.flipped ? (
              <div
                style={{
                  position: "relative",
                  top: 140,
                  right: 0,
                  left: 0,
                  width: "100%",
                  transform: `${props.reverse ? " scaleX(-1)" : ""}`,
                }}
              >
                <Wave
                  id={props.id}
                  width={"100%"}
                  height={500}
                  fill={props.fill}
                  gradientFill={props.gradientFill}
                />
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  bottom: 140,
                  left: 0,
                  right: 0,
                  transform: `scaleY(-1)${props.reverse ? " scaleX(-1)" : ""}`,
                  width: "100%",
                }}
              >
                <Wave
                  id={props.id}
                  width={"100%"}
                  height={500}
                  fill={props.fill}
                  gradientFill={props.gradientFill}
                />
              </div>
            )}
          </Flex>
        )}
        <Flex
          className={style.foreground}
          column
          style={{
            width: "100%",
            backgroundColor: showWave ? undefined : props.fill,
          }}
        >
          {props.children}
        </Flex>
      </Flex>
    </Flex>
  );
};
