import React from 'react';
import { Flex } from '..';
import { CfpLogo, CkpLogo, CfaLogo } from '../../Assets';
import { ReusableStaticImage } from '..';
import style from './style.module.scss';

export const CertificationBanner = () => {
    return (
        <Flex className={style.banner_wrapper} justify="space-evenly" align="space-evenly">
            <ReusableStaticImage style={style.certification_logo} image={CfpLogo} width={8} />
            <ReusableStaticImage style={style.certification_logo} image={CkpLogo} width={8} />
            <ReusableStaticImage style={style.certification_logo} image={CfaLogo} width={8} />
        </Flex>
    );
};
