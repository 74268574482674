import React, { useMemo } from 'react'
import { Flex } from '../..';
import style from './style.module.scss';
import { BusinessStatistics } from '../../LottieFIles';
import { RequestAppointmentButton } from '../../Button/RequestAppointmentButton';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Redux/Models';
import { WindowSizes } from '../../../Redux/Models/Utility/WIndowSizes';

export const LandingSection = (props: any) => {

  const appSize = useSelector((s: AppState) => s.utility.appWindowSize);

  const isVertical = useMemo(() => {
    switch (appSize) {
      case WindowSizes.large: case WindowSizes.extraLarge: case WindowSizes.regular: case WindowSizes.large: case WindowSizes.small: return false;
      default: return true;
    }
  }, [appSize])

  const imageWidth = useMemo(() => {
    switch (appSize) {
      case WindowSizes.tinyMobile:
      case WindowSizes.smallMobile:
      case WindowSizes.mobile:
      case WindowSizes.smallTablet:
      case WindowSizes.tablet: return '70vw';
      default: return '40vw';
    }
  }, [appSize]);

  const buttonWidth = useMemo(() => {
    switch (appSize) {
      case WindowSizes.tinyMobile: return '100%';
      case WindowSizes.smallMobile: return '95%';
      case WindowSizes.mobile: return '70%';
      case WindowSizes.smallTablet:
      case WindowSizes.tablet: return '60%';
      default: return null;
    }
  }, [appSize])

  return (
    <Flex className={style.wrapper}>
      <Flex
        justify="center"
        align="center"
        className={style.content}
        style={isVertical ? { marginBottom: 50 } : undefined}
      >
        <Flex column justify="center" align="flex-start" style={{ flex: 1 }}>
          <h2 className={style.solutionsHeader}>Solutions for what matters most</h2>
          <p>
            Our mission is to be a wealth advisory firm that truly understands what matters most to our clients and is a thoughtful steward of their complete financial picture. Below is our full suite of services.
          </p>
          {!isVertical && <RequestAppointmentButton />}
        </Flex>
        <Flex className={style.lottieWrapper}><BusinessStatistics size={imageWidth} /></Flex>
        {isVertical && <Flex center style={{ marginTop: 20, width: "100%" }}><RequestAppointmentButton style={{ width: buttonWidth }} /></Flex>}
      </Flex>
    </Flex>
  );
};
