import React, { useState } from "react";
import { PortraitModal } from "../Modals/PortraitModal";
import style from "./style.module.scss";
import {
  Add,
  AddRounded,
  CloseRounded,
  ControlPoint,
} from "@mui/icons-material";
import { Flex } from "../Flex";

export interface PortraitCardProps {
  id?: any;
  src: string;
  title?: string;
  headline?: string;
  description?: string;
}

export const PortraitCard = ({
  id,
  src,
  title,
  headline,
  description,
}: PortraitCardProps) => {
  const [isSelected, setSelected] = useState(false);

  return (
    <>
      <div className={style.card} onClick={() => setSelected(true)}>
        <div className={`${style.cardContentContainer}`}>
          <div className={style.cardContent}>
            <div className={style.cardImageContainer}>
              <img
                alt=""
                src={src}
                className={style.cardImage}
                draggable={false}
              />
            </div>
            <div className={style.cardTitleContainer}>
              <h2 className={style.cardTitle}>{title}</h2>
            </div>
            <Flex center className={style.controlPointWrapper}>
              <AddRounded style={{ color: "#fff", cursor: "pointer" }} />
            </Flex>
          </div>
        </div>
      </div>
      <PortraitModal
        id={id}
        data={{ image: src, title, description, headline }}
        isOpen={isSelected}
        toggleModal={() => setSelected(false)}
      />
    </>
  );
};
