import React from "react";
import { ModalWrapper } from "../ModalWrapper";
import { Flex } from "../../Flex";
import style from "./style.module.scss";
import { HashLink as Link } from "react-router-hash-link";

export interface PortraitModalProps {
  data: any;
  isOpen: boolean;
  toggleModal: (nextState: boolean) => void;
  id?: any;
}

export const PortraitModal = ({
  data,
  isOpen,
  toggleModal,
  id,
}: PortraitModalProps) => {
  const LearnMoreLink = () => {
    if (!id) return null;
    return (
      <Link smooth to={id}>
        <span className={style.learnMoreLink}>Learn more</span>
      </Link>
    );
  };

  return (
    <ModalWrapper
      padding={0}
      borderRadius={15}
      isOpen={isOpen}
      toggleModal={toggleModal}
      withHeader={false}
    >
      <Flex column className={style.container}>
        <Flex className={style.imageWrapper}>
          <Flex
            className={style.image}
            style={{ backgroundImage: `url(${data.image}` }}
          >
            <h2 className={style.title}>{data.title}</h2>
          </Flex>
        </Flex>
        <Flex column className={style.textContainer}>
          <h3 className={style.headline}>{data.headline}</h3>
          <p className={style.description}>
            {data.description} <LearnMoreLink />
          </p>
        </Flex>
      </Flex>
    </ModalWrapper>
  );
};
