import React, { useCallback } from "react";
import style from "./style.module.scss";
import { IgIcon, TwitterIcon, FbIcon, LinkedinIcon } from "../../Assets/Icons";
import { ReusableStaticImage } from "../ReusableStaticImage/ReusableStaticImage";
import { Flex } from "../Flex";

export const SocialMediaBlock = () => {
  const onIconClick = useCallback(
    (url: string) => window.open(url, "_blank"),
    []
  );
  return (
    <Flex className={style.mediaIcons}>
      <ReusableStaticImage
        onClick={() => onIconClick("https://www.facebook.com/ehadvisor/")}
        image={FbIcon}
        width={1.2}
        style={style.icon}
      />
      <ReusableStaticImage
        onClick={() =>
          onIconClick("https://www.instagram.com/safe_with_eagleharbor/")
        }
        image={IgIcon}
        width={1.2}
        style={style.icon}
      />
      <ReusableStaticImage
        onClick={() => onIconClick("https://twitter.com/GSmartMoney")}
        image={TwitterIcon}
        width={1.2}
        style={style.icon}
      />
      <ReusableStaticImage
        onClick={() => onIconClick("https://www.linkedin.com/company/77159197")}
        image={LinkedinIcon}
        width={1.2}
        style={style.icon}
      />
    </Flex>
  );
};
