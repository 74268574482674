import React, { useState } from 'react';
import { Flex } from '../../Flex';
import { Button } from '../..';
import { MediaModal } from '../../Modals/MediaModal';
import { RmdCalculator as Calculator, Iframe } from '../../ContentWidgets';
import { CloseRounded, PlayCircleOutline } from "@mui/icons-material";
import { useWindowSize } from '../../../Hooks';
import style from './style.module.scss';

interface ContentThumbnailProps {
    data: any;
}

export const ContentThumbnail = (props: ContentThumbnailProps) => {
    const { data } = props;
    const { width } = useWindowSize();
    const [hovered, setHovered] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Flex
                id={data?.id}
                className={style.wrapper}
                center
                column
                onClick={() => setIsOpen(true)}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}>
                <Flex align="flex-start" className={style.title}>
                    <h3>{data?.Title}</h3>
                </Flex>
                <Flex
                    center
                    style={{ background: hovered ? '#141c3a' : `url(${data?.Thumbnail?.url}` }}
                    className={style.background_image}>
                    {data?.IsVideo && !hovered && <Flex className={style.overlay}>
                        <PlayCircleOutline className={style.videoControl} />
                    </Flex>}
                    {
                        (hovered || width && width < 350) &&
                        <Flex column center>
                            <span className={style.description}>
                                {data?.Description}
                            </span>
                            <Button className={style.action_btn} theme="orange">Learn More</Button>
                        </Flex>
                    }
                </Flex>
            </Flex>
            <MediaModal isOpen={isOpen} toggleModal={() => setIsOpen(false)}>
                <Iframe url={data?.Url} />
                <div
                    onClick={() => setIsOpen(false)}
                    style={{ position: 'absolute', top: 25, right: 1, padding: '25px', cursor: 'pointer' }}>
                    <CloseRounded
                        style={{ color: "#2A93D1", cursor: 'pointer', fontSize: '40px' }}
                    />
                </div>
            </MediaModal>
        </>
    );
};
