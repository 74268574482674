import React, { useEffect } from 'react';
import SR from 'scrollreveal';
import { Flex, Button } from "../..";
import { RequestAppointmentButton } from '../../Button/RequestAppointmentButton';
import style from "./style.module.scss";

export interface LeftSectionProps {
    header?: string;
    grabber?: string;
    buttonLabel?: string;
    onClick?: (e?: any) => any;
    color?: string;
    buttonTheme?: "callToActionBlack" | "callToActionWhite";
    hideAppointmentBtn?: boolean;
}

export const LeftSection = React.memo((props: LeftSectionProps) => {
    const { header, grabber, buttonTheme, onClick = () => { }, color, hideAppointmentBtn } = props;

    useEffect(() => {
        SR().reveal('.content_wrapper', { opacity: 0.2, viewFactor: 0.3, scale: 0.9, delay: 0, reset: false, distance: '50px', origin: 'left' });
    }, []);

    return (
        <Flex
            className={`content_wrapper ${style.wrapper}`}
            column
            justify="center"
            align="flex-start"
            style={{ width: "50vw", color: `${color}` && `${color}` }}
        >
            <h2>{header}</h2>
            <p className={style.content} style={{ color: `${color}` && `${color}` }}>
                {grabber}
            </p>
            {!hideAppointmentBtn && <RequestAppointmentButton theme={buttonTheme} />}
        </Flex>
    );
});
