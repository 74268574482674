import React from 'react';
import { GlenBioImage, TeamImageSecondary, PreFlight, FlightPlan, RequestClearance, AcceptClearance, WaypointMonitoring } from "../Assets"
import { AndyBioImage } from "../Assets"
import { GirishBioImage } from "../Assets"
import { Flex } from '../Components';
import { RiskIcon, SettingsChartIcon, ChartUpIcon, Portfolio, BarChart, Plan, MitigatingRisk, PlanningPilots, StockOptions } from '../Assets/Icons';
import { BulletItem } from '../Components/BulletItem/BulletItem';
import { HashLink as Link } from 'react-router-hash-link';

/**
 * We need to work on replacing these with the respective CMS db values - Roman C.
 */

export const TeamBio = [
    {
        name: "Glen Turner",
        FullName: "Glen Turner",
        Hierarchy: "Partner",
        id: 'glen',
        title: 'Partner',
        company: "Eagle Harbor Advisors",
        image: GlenBioImage,
    },
    {
        name: "Andy Wilkins",
        id: 'andy',
        title: 'Partner',
        company: "Eagle Harbor Advisors",
        image: AndyBioImage,
        FullName: "Andy Wilkins",
        Hierarchy: "Partner",
    },
    {
        name: "Girish Navare, CFA®",
        title: 'Director of Investment Strategies',
        id: 'girish',
        email: 'gnavare@ehadvisor.com',
        company: "Eagle Harbor Advisors",
        image: GirishBioImage,
        FullName: "Girish Navare, CFA®",
        Title: "Director of Investment Strategies",
    }
]

export const EventsContent = [
    {
        name: "Whiskey & Wheels",
        address: '101 Lake Avenue, Orlando, FL 32801',
        datetime: "January 8th, 2022 @ 7:00 pm ET",
        preview: "In this presentation, Girish Navare, CFA®, will talk briefly about John Hancock Investment Management and their approach to investing. He will review what they are hearing from their network as it relates to U.S. equities, international equities, and fixed-income asset classes over the next 12 to 18 months. Additionally, he will cover some themes that he believes are worth considering at the overall portfolio level.",
        description: "In this presentation, Girish Navare, CFA®, will talk briefly about John Hancock Investment Management and their approach to investing. He will review what they are hearing from their network as it relates to U.S. equities, international equities, and fixed-income asset classes over the next 12 to 18 months. Additionally, he will cover some themes that he believes are worth considering at the overall portfolio level.",
    },
    {
        name: "Whiskey & Wheels",
        address: '101 Lake Avenue, Orlando, FL 32801',
        datetime: "January 8th, 2022 @ 7:00 pm ET",
        preview: "In this presentation, Girish Navare, CFA®, will talk briefly about John Hancock Investment Management and their approach to investing. He will review what they are hearing from their network as it relates to U.S. equities, international equities, and fixed-income asset classes over the next 12 to 18 months. Additionally, he will cover some themes that he believes are worth considering at the overall portfolio level.",
        description: "In this presentation, Girish Navare, CFA®, will talk briefly about John Hancock Investment Management and their approach to investing. He will review what they are hearing from their network as it relates to U.S. equities, international equities, and fixed-income asset classes over the next 12 to 18 months. Additionally, he will cover some themes that he believes are worth considering at the overall portfolio level.",
    },
    {
        name: "Whiskey & Wheels",
        address: '101 Lake Avenue, Orlando, FL 32801',
        datetime: "January 8th, 2022 @ 7:00 pm ET",
        preview: "In this presentation, Girish Navare, CFA®, will talk briefly about John Hancock Investment Management and their approach to investing. He will review what they are hearing from their network as it relates to U.S. equities, international equities, and fixed-income asset classes over the next 12 to 18 months. Additionally, he will cover some themes that he believes are worth considering at the overall portfolio level.",
        description: "In this presentation, Girish Navare, CFA®, will talk briefly about John Hancock Investment Management and their approach to investing. He will review what they are hearing from their network as it relates to U.S. equities, international equities, and fixed-income asset classes over the next 12 to 18 months. Additionally, he will cover some themes that he believes are worth considering at the overall portfolio level.",
    },
    {
        name: "Whiskey & Wheels",
        address: '101 Lake Avenue, Orlando, FL 32801',
        datetime: "January 8th, 2022 @ 7:00 pm ET",
        preview: "In this presentation, Girish Navare, CFA®, will talk briefly about John Hancock Investment Management and their approach to investing. He will review what they are hearing from their network as it relates to U.S. equities, international equities, and fixed-income asset classes over the next 12 to 18 months. Additionally, he will cover some themes that he believes are worth considering at the overall portfolio level.",
        description: "In this presentation, Girish Navare, CFA®, will talk briefly about John Hancock Investment Management and their approach to investing. He will review what they are hearing from their network as it relates to U.S. equities, international equities, and fixed-income asset classes over the next 12 to 18 months. Additionally, he will cover some themes that he believes are worth considering at the overall portfolio level.",
    },
]

export const WebinarContent = [
    {
        topic: "Exit Strategies for Business Owners",
        date: 'Monday, December 13th, 2021',
        time: "7:00 pm ET",
    },
    {
        topic: "Exit Strategies for Business Owners",
        date: 'Monday, December 13th, 2021',
        time: "7:00 pm ET",
    },
    {
        topic: "Exit Strategies for Business Owners",
        date: 'Monday, December 13th, 2021',
        time: "7:00 pm ET",
    },
    {
        topic: "Exit Strategies for Business Owners",
        date: 'Monday, December 13th, 2021',
        time: "7:00 pm ET",
    },
    {
        topic: "Exit Strategies for Business Owners",
        date: 'Monday, December 13th, 2021',
        time: "7:00 pm ET",
    },
    {
        topic: "Exit Strategies for Business Owners",
        date: 'Monday, December 13th, 2021',
        time: "7:00 pm ET",
    },
]

export const Solutions = [
    {
        title: 'Corporate Retirement',
        image: Plan,
        description: 'Having a framework to support informed decision making aligned with fiduciary roles and responsibilities is critical to meeting regulatory requirements and fulfilling participant expectations. Your organization has specific goals, and your corporate retirement plan should be responsive to those goals.',
        width: 3,
        id: 'retirement',
        // to: '#corporate-retirement'
    },
    {
        title: 'Financial Planning',
        image: BarChart,
        description: 'Business owners and entrepreneurs are highly motivated people. They are driven to make their companies successful. They are always on the go, and they try to seize every opportunity. But that single-minded focus can take a toll on other aspects of life, including managing one’s personal finances.',
        width: 3,
        id: 'financial-business'
        // to: '#corporate-retirement'
    },
    {
        title: 'Exit Planning',
        image: Portfolio,
        description: 'Most entrepreneurs spend their lives creating and building the vision, developing the business, adjusting to market evolution and riding out the company’s storms. But what do you do when it’s time to settle down, retire, or just move on from the day-to-day operational components of your successful business?',
        width: 3,
        id: 'exit',
        to: '#corporate-retirement'
    },
]

export const PilotSolutions = [
    {
        title: 'Retirement Planning',
        image: PlanningPilots,
        description: 'When you’ve never done it before, it’s hard to figure out when to retire and how to make your money last. Ultimately, that’s what it’s all about: having enough income to support yourself in the lifestyle you want and with the confidence that your money will last your entire lifetime.',
        width: 3,
        id: 'financial-planning-pilots',
        // to: '#corporate-retirement'
    },
    {
        title: 'Mitigating Risk',
        image: MitigatingRisk,
        description: 'It takes an in depth understanding of the unique challenges aviation professionals face such as health and medical requirements, mandatory pilot retirement at age 65, airline specific risks such as mergers and acquisitions, furloughs, bankruptcies and constantly changing benefit packages including retirement plans demand that you receive competent and comprehensive financial advice.',
        width: 3,
        id: 'mitigating-risk-pilots',
        // to: '#corporate-retirement'
    },
    {
        title: 'Stock Options',
        image: StockOptions,
        description: 'Eagle Harbor Advisors have specialist that are uniquely qualified to work with Pilots. We will help you make the most of your income, stock options, and benefits to ensure you avoid landing short on your financial final approach. ',
        width: 3,
        id: 'stock-options-pilots',
        // to: 'stock-options-pilots'
    },
]

export const PilotsContent = [
    {
        paragraph: 'You need a partner you can trust.  Eagle Harbor Advisors views its role in this relationship as the invaluable copilot, assisting you to reach your financial destinations safely and on time.',
    },
    {
        paragraph: 'To address these needs, we have developed our own unique 6 step process designed to help pilots and aviation professionals get on course and ensure they are prepared for their transition and financial final approach.',
    },
]

export const PilotsBulletPoints = [
    {
        point: 'Step 1: Meet the Crew (Establish & Define Relationship)',
    },
    {
        point: 'Step 2: Pre-Flight Planning (Gather Client Information & Data)',
    },
    {
        point: 'Step 3: Developing the Flight Plan (Data Analysis & Plan Development)',
    },
    {
        point: 'Step 4: Request Clearance (Present the Plan)',
    },
    {
        point: 'Step 5: Accept Clearance and Route Adjustments (Adapt Plan & Create Action Plans)',
    },
    {
        point: 'Step 6: Waypoint Monitoring/Course Corrections (Monitor progress and adjust as needed)',
    },
]

export const PilotStepProcess: any[] = [
    {
        reverse: true,
        header: "Meet the Crew",
        type: "white",
        id: "meet-the-crew",
        dropShadowColor: 'rgba(55, 167, 219, 0)',
        foregroundBackgroundColor: 'transparent',
        src: TeamImageSecondary,
        allowOverflow: true,
        content: `We share a passion for Aviation with the freedom to embrace cutting-edge technology, unlimited product offerings, and an environment that encourages new and creative solutions. Eagle Harbor Advisors knows a Crew with the correct chemistry is critical to meet your needs. Eagle Harbor Advisors understands Airline Professionals. Glen Turner, Senior Partner/Managing Director served as a military officer, C-130 Special Ops Instructor Aircraft Commander, United States Air Force from 1985 to 1992. He has over 19,000 Flying hours and is type rated on the B727, A310, B757,B767, B777 and L382.`,
        // scrollId: 'team-bio',
        // scrollTo: `/about`,
        // buttonLabel: 'View Team Bio`s',
        extraContent: () => (
            <Flex column>
                <p style={{ fontSize: '22px', fontWeight: 500, lineHeight: 1.5, color: '#000000' }}>He was awarded the USAF Air Medal for Aerial Combat while on Active Duty during Operation Desert Storm. He continued his aviation career as a pilot with American Airlines beginning in 1992 and holds Type Ratings on B727, A310, B757, B767, B777 and L382 Aircraft. He has worked with pilots and Aviation Professionals for over 30 years, helping hundreds of pilots Achieve peace of mind with an intimate and deep understanding of their intricate and sometimes complex retirement plans.</p>
                <Flex column style={{ marginTop: 5, marginBottom: 15, width: '100%' }}>
                    <Flex column align="flex-start" style={{ width: '100%' }}>
                        <BulletItem variant='jsx' content={<p style={{ margin: 0, padding: 0, color: '#000000', lineHeight: 1.5 }}>A relationship that's responsive, attentive, and personal</p>} />
                        <BulletItem variant='jsx' content={<p style={{ margin: 0, padding: 0, color: '#000000', lineHeight: 1.5 }}>A simple and transparent fee structure</p>} />
                        <BulletItem variant='jsx' content={<p style={{ margin: 0, padding: 0, color: '#000000', lineHeight: 1.5 }}>A high level of expertise to support your complex and changing financial needs</p>} />
                    </Flex>
                </Flex>
                <p style={{ fontSize: '22px', fontWeight: 500, lineHeight: 1.5, color: 'white' }}>Eagle Harbor Advisors’ uses cutting edge Technology, and a hands-on approach designed to give you the confidence that you have selected a highly qualified and well-equipped crew.
                </p>
            </Flex >
        )
    },
    {
        reverse: false,
        header: "Pre-Flight Planning",
        type: "wave",
        id: "pre-flight",
        fill: "#2A93D1",
        dropShadowColor: 'rgba(55, 167, 219, 0)',
        foregroundBackgroundColor: 'transparent',
        imageBackgroundColor: 'transparent',
        allowOverflow: true,
        src: PreFlight,
        content: "We help you take control of your finances and fully organize your money. We will identify your values, goals, priorities, preferences, as well as potential obstacles. We will mutually establish what your expectations are for Eagle Harbor Advisors and whether an ongoing partnership is the right fit.",
    },
    {
        reverse: true,
        header: "Developing the Flight Plan",
        type: "wave-flipped",
        id: "flight-plan",
        fill: "#2A93D1",
        dropShadowColor: 'rgba(55, 167, 219, 0)',
        imageBackgroundColor: 'transparent',
        foregroundBackgroundColor: 'transparent',
        allowOverflow: true,
        src: FlightPlan,
        content: "You need to know where you are in order to plan to reach your financial destination. Establishing realistic and attainable goals helps lay the path for piloting your equipment to a safe landing. Eagle Harbor Advisors technology platform is powered in part by eMoney, the top-rated financial planning software, which shares our commitment to enhancing the financial planning experience. As a leader in the industry, we deliver the solutions you need to help ensure a safe flight and smooth landing. We prioritize what matters most to you. No worries, you can reorganize your priorities if they change over time. We will discuss Income, Expenses, and Savings plus Annual Living Expenses, Annual Contributions and Savings.",
    },
    {
        reverse: false,
        header: "Request Clearance",
        type: "white",
        id: "request-clearance",
        dropShadowColor: 'rgba(55, 167, 219, 0.5)',
        src: RequestClearance,
        content: "Eagle Harbor Advisors will deliver a financial plan with investments that fit your life goals. Upon review, we will make any necessary adjustments and provide you with a summary and list of recommendations. Once you have cleared us for Take-off, we will be with you each step of the way to your financial destination. This will likely include joint calls to other firms, joint meetings with your other professional advisors (CPA, Attorney etc...) or family members. ",
    },
    {
        reverse: true,
        header: "Accept Clearance and Route Adjustments",
        type: "white",
        id: "accept-clearance",
        dropShadowColor: 'rgba(55, 167, 219, 0.5)',
        src: AcceptClearance,
        content: "You always remain the Captain of your financial flight. Once you instruct us to go forward with your financial plan, you will receive personal attention with tailored communication at regular intervals every year. Eagle Harbor Advisors is available for “instrument and altitude checks”. You can always schedule additional meetings as needed and leverage email and phone support for accountability, guidance, updates, or ad-hoc planning questions.",
    },
    {
        reverse: false,
        header: "Waypoint Monitoring/Course Corrections",
        type: "wave",
        id: "waypoint-monitoring",
        fill: "#2A93D1",
        dropShadowColor: 'rgba(55, 167, 219, 0)',
        imageBackgroundColor: 'transparent',
        foregroundBackgroundColor: 'transparent',
        allowOverflow: true,
        src: WaypointMonitoring,
        content: "Once we are enroute, we will conduct regular,periodic review meetings. Eagle Harbor regularly helps to incorporate recent life changes into your plan. As your Co-Pilot, Eagle Harbor Advisors will alert you to Turbulence and continually vector you toward a safe arrival at each of your financial destinations.",
    }
]


export const ExampleBannerSection = [
    {
        label: 'Minimize Risk',
        image: RiskIcon,
        width: 9,
    },
    {
        label: 'Equip Employees',
        image: SettingsChartIcon,
        width: 10,
    },
    {
        label: 'Maximize Results',
        image: ChartUpIcon,
        width: 10,
    },
]