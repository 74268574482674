import React from "react";
import { ModalWrapper } from "../ModalWrapper";
import { ProfileDetails } from "./ProfileDetails";

export interface BioModalProps {
  data: any;
  isOpen: boolean;
  toggleModal: (nextState: boolean) => void;
  key?: any;
}

export const BioModal = React.memo((props: BioModalProps) => {
  const { data, isOpen, toggleModal, key } = props;
  return (
    <ModalWrapper isOpen={isOpen} toggleModal={toggleModal}>
      <ProfileDetails key={key} data={data} />
    </ModalWrapper>
  );
});
