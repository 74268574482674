import React from 'react';
import { Flex } from '../..';
import style from './style.module.scss';
import { TeamBio } from '../../../Constants/Content';

interface PresenterCardProps {
    data?: any;
}

export const PresenterCard = (props: PresenterCardProps) => {
    const { data } = props;
    return (
        <Flex className={style.wrapper}>
            <Flex center className={style.image}>
                <img style={{ width: '65px', height: '65px', borderRadius: '50%' }} draggable="false" src={data?.image} alt="empty" />
            </Flex>
            <Flex column justify="center" className={style.details}>
                <span className={style.name}>{data?.name}</span>
                <span className={style.company}>{data?.company}</span>
                <span className={style.title}>{data?.title}</span>
            </Flex>
        </Flex>
    );
};
