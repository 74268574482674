import React from 'react';
import { Flex } from '..';
import { ParallaxWrapper } from '../ParallaxWrapper';
import { Office, ExitStrat, CorporatePlanning } from '../../Assets';
import { SolutionsSection } from './SolutionsSection';
import { ParallaxContent } from './ParallaxContent';
import { ContentSection } from '../ContentSection';
import { ExampleBannerSection } from '../../Constants/Content';
import { ContentBanner } from '..';
import style from './style.module.scss';
import { BulletItem } from '../BulletItem/BulletItem';

const BannerContent = () => {
    return (
        <Flex center>
            <span className={style.banner_content} style={{ textAlign: 'center', lineHeight: 1.4, width: '80%' }}>
                <strong style={{ color: '#2B93D1', marginRight: '5px' }}>
                    {/* Key considerations, such as corporate structure, business objectives, and cash flow are factors in the decisions related to the appropriate corporate retirement plan design for the business. */}
                    Eagle Harbor Advisors have a long history of working with successful retirement plan sponsors.
                </strong>
                We have seen how a strategic approach to fiduciary responsibility can drive improved participant outcomes, help minimize fiduciary risk and supports the ability to adapt plans to changing business environments and goals.
            </span>
        </Flex>
    )
}

const BusinessServices: any[] = [
    {
        reverse: false,
        header: "Financial Planning for Business Owners",
        type: "white",
        id: "financial-business",
        dropShadowColor: 'rgba(55, 167, 219, 0.5)',
        content: "Business owners and entrepreneurs are highly motivated people. They are driven to make their companies successful. They are always on the go, and they try to seize every opportunity. But that single-minded focus can take a toll on other aspects of life, including managing one’s personal finances.",
        extraContent: () => (
            <Flex column>
                <p style={{ fontSize: '22px', fontWeight: 500, lineHeight: 1.5 }}>Eagle Harbor Advisors understands the challenges that business owners face. As Independent Advisor, we too are business owners, and we have been providing financial planning for business owners in the South Florida area for nearly four decades.</p>
                <p style={{ fontSize: '22px', fontWeight: 500, lineHeight: 1.5 }}>In creating financial plans for entrepreneurs in all types of businesses, we have learned which personal financial issues keep business owners up at night and interrupt their focus on their companies during the day. With our knowledge and experience, we are in a position to offload that stress by becoming partners in the management of any business owner’s long-term financial future.</p>
                <Flex column style={{ marginTop: 5, marginBottom: 15 }}>
                    <p style={{ fontSize: '22px', fontWeight: 500, lineHeight: 1.5 }}>Our <span style={{ color: '#2A93D1', fontWeight: 'bold' }}>comprehensive financial planning</span> services developed specifically for business owners takes into account both family priorities and business needs. We carefully develop financial plans that take advantage of opportunities to build security for business owners and their families. Our services include:</p>
                    <Flex column align="flex-start" style={{ width: '100%' }}>
                        <BulletItem content="Asset allocation and ongoing investment management advice" />
                        <BulletItem content="Retirement plan design and Fiduciary Advisory Services" />
                        <BulletItem content="Risk management" />
                        <BulletItem content="Estate planning" />
                        <BulletItem content="Cash flow management" />
                        <BulletItem content="Planning for new investors, partners or successors" />
                    </Flex>
                </Flex>
                <p style={{ fontSize: '22px', fontWeight: 500, lineHeight: 1.5 }}>Find out how we will develop and monitor a long-term financial plan designed for your unique needs. With new confidence that your financial future is on the right track, you can get back to the business of building your company.</p>
            </Flex>
        ),
        src: CorporatePlanning,
    },
    {
        reverse: true,
        header: " Exit & Succession Planning",
        type: "wave",
        id: "exit",
        fill: "#201E50",
        dropShadowColor: 'rgba(55, 167, 219, 0)',
        imageBackgroundColor: 'transparent',
        foregroundBackgroundColor: 'transparent',
        allowOverflow: true,
        content: "Most entrepreneurs live for the thrill of building their businesses. We spend our lives creating and building the vision, developing the business, adjusting to market evolution and riding out the company’s storms. But what do you do when it’s time to settle down, retire, or just move on from the day-to-day operational components of your successful business?",
        extraContentBackground: 'rgb(32,30,80)',
        extraContent: () => (
            <Flex column style={{ background: 'rgb(32,30,80)' }}>
                <p style={{ fontSize: '22px', fontWeight: 500, lineHeight: 1.5, color: 'white' }}>There are a number of exit and succession strategies that you can consider. Each one requires forethought and planning so the sooner you start planning your exit strategy, the more effective the process will be. Before you can decide on which one is best for you, should carefully consider your own goals and what you see as a successful outcome for your exit:</p>
                <Flex column style={{ marginTop: 5, marginBottom: 15 }}>
                    <Flex column align="flex-start" style={{ width: '100%' }}>
                        <BulletItem variant='jsx' content={<p style={{ margin: 0, padding: 0, color: 'white', lineHeight: 1.5 }}>Do you want to see the business live on beyond your tenure?</p>} />
                        <BulletItem variant='jsx' content={<p style={{ margin: 0, padding: 0, color: 'white', lineHeight: 1.5 }}>Do you want it to pass to family members, partners or key employees?</p>} />
                        <BulletItem variant='jsx' content={<p style={{ margin: 0, padding: 0, color: 'white', lineHeight: 1.5 }}>Are you ready to just cash out and retire on a sunny tropical island in the Caribbean?</p>} />
                    </Flex>
                </Flex>
                <Flex column style={{ marginTop: 5, marginBottom: 15 }}>
                    <h3 style={{ marginBottom: 15, color: 'white' }}>Let’s explore a few of the many options available to you for exit or succession of your business:</h3>
                    <BulletItem variant='jsx' content={<p style={{ padding: 0, margin: 0, lineHeight: 1.5, color: 'white' }}><span style={{ color: "#80ffe8", fontWeight: 'bolder' }}>Direct Succession –</span> Succession strategies can take many different forms. A succession strategy typically involves “passing on” the company to a family member, partner, an employee, a group of partners/employees, while creating the bridge for someone else to successfully assume your key role in the business.</p>} />
                    <BulletItem variant='jsx' content={<p style={{ padding: 0, margin: 0, lineHeight: 1.5, color: 'white' }}><span style={{ color: "#80ffe8", fontWeight: 'bolder' }}>Selling the Business –</span> This can also take many forms, including hybrids of the succession strategy. A business can be sold to family members, partners or employees, or to a third party. Third-party sales of a company can include selling to “friendly” buyers or even competitors. One thing to consider if you are looking toward selling the company as an exit strategy is to prepare your company to be desirable to outside buyers.</p>} />
                    <BulletItem variant='jsx' content={<p style={{ padding: 0, margin: 0, lineHeight: 1.5, color: 'white' }}><span style={{ color: "#80ffe8", fontWeight: 'bolder' }}>IPOs –</span> While IPOs sound sexy and dramatic, they aren’t the best option for most businesses. In fact, of the millions of businesses in the U.S. alone, only several thousand are public companies. Nonetheless, for certain types of businesses with huge market potential, an IPO can be a massive windfall of cash that allows a business owner to secure their future moving forward.</p>} />
                    <BulletItem variant='jsx' content={<p style={{ padding: 0, margin: 0, lineHeight: 1.5, color: 'white' }}><span style={{ color: "#80ffe8", fontWeight: 'bolder' }}>Just Shut It Down –</span> Sometimes, the best option is simply to wind down the company, cash out, liquidate assets and move on. One strategy for the “walk away” option is to start taking your money out of the company early in larger amounts over time through ever-increasing salaries. Many entrepreneurs have the majority, or even all of their revenue and assets tied up in their business, so getting creating a viable strategy for getting that money out becomes the magic formula.</p>} />
                </Flex>
                <p style={{ fontSize: '22px', fontWeight: 500, lineHeight: 1.5, color: 'white' }}>There is no time like the present to start developing your exit or succession strategy so you can have peace of mind in knowing that you’ll meet your goals and achieve your desired outcomes. The professionals at Eagle Harbor Advisors have the experience and the expertise to help you successfully select, navigate and complete the best strategy for you.</p>
            </Flex >
        ),
        src: ExitStrat,
    },
]

const SectionBannerContent = () => {
    return (
        <Flex className={style.retirement_content} center style={{ width: '100%', color: '#fff' }}>
            <Flex column center style={{ flex: 1 }}>
                <p style={{ width: '80%', lineHeight: 1.5, fontSize: '1rem' }}>
                    Key considerations, such as corporate structure, business objectives, and cash flow are factors in the decisions related to the appropriate corporate retirement plan design for the business.
                </p>
                <p style={{ width: '80%', lineHeight: 1.5, fontSize: '1rem' }}>
                    Now more than ever plan sponsors are rethinking their corporate retirement plans. Eagle Harbor Advisors leading Retirement Plan Review and Benchmarking service provides plan sponsors with a framework to:
                </p>
            </Flex>
            <Flex className={style.bullet_points} column style={{ flex: 0.7 }}>
                <BulletItem
                    content="Assess fiduciary risk" />
                <BulletItem
                    content="Review competitiveness of participant outcomes" />
                <BulletItem
                    content="Coordinate plan resources to maximize result" />
                <BulletItem
                    content="Limit liability" />
                <BulletItem
                    content="Achieve your goals" />
            </Flex>
        </Flex>
    )
}

export const BusinessSolutions = () => {
    return (
        <div id="business-main" className={style.wrapper}>
            <ParallaxWrapper height="65vh" classes={style.parallax_wrapper} src={Office} children={<ParallaxContent />} />
            <SolutionsSection />
            <ContentBanner
                id="retirement"
                header="Corporate Retirement Plan Design & Fiduciary Advisory Service"
                // header="Employer Sponsored Financial Wellness"
                grabber={<BannerContent />}
                data={ExampleBannerSection}
            />
            <ContentBanner
                header={<span style={{ color: '#fff' }}>Retirement Plan Review and Benchmarking</span>}
                backgroundColor="#201E50"
                children={<SectionBannerContent />}
            />
            {BusinessServices.map((s, key) => <ContentSection {...s} id={`${s.id}`} fill={s.fill} type={s.type} src={s.src} key={`${s.id}-${key}`} header={s.header} content={s.content} reverse={s.reverse} />)}
        </div>
    );
};
